
.testimonial-slider-bx{
    max-width: 768px;
    width: 100%;
    margin: 0 auto;
    position: relative;
    padding: 0 60px;
}

.testimonial-slider-bx::before,
.testimonial-slider-bx::after{
    content: "";
    position: absolute;
    width: 50px;
    height: 50px;
    z-index: 1;
    background-image: url("../img/icons/quote.png");
    background-repeat: no-repeat;
    background-size: contain;
    transform-origin: center;
    opacity: 0.8;
}

.testimonial-slider-bx::after{
    top: 0;
    left: 0;
    transform: rotate(190deg);
}

.testimonial-slider-bx::before{
    right: 0;
    bottom: 50px;
    transform: rotate(10deg);
}

.testimonial-card-item{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 15px;
}

.testimonial-card-item .tci-text{
    color: var(--textcolor);
    font-weight: 500;
    font-size: 18px;
    text-align: center;
}

.testimonial-card-item .tci-name{
    color: var(--black);
    font-weight: 600;
    font-size: 20px;
}

.testimonial-card-item .tci-star-bx{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: -10px;
}

.testimonial-card-item .tci-star-bx span{
    width: 22px;
    height: 22px;
}

.testimonial-card-item .tci-star-bx span.active{
    color: var(--gold);
}

.testimonial-card-item .tci-star-bx span svg{
    object-fit: contain;
    object-position: center;
}


.tsb-img{
    position: absolute;
}

.tsb-img.tsb1{
    left: 15%;
    top: 5%;
}

.tsb-img.tsb2{
    right: 15%;
    top: 5%;
}

.tsb-img.tsb3{
    left: 0%;
    top: 50%;
    transform: translateY(-50%);
}

.tsb-img.tsb4{
    right: 0;
    top: 50%;
    transform: translateY(-50%);
}

.tsb-img.tsb5{
    left: 10%;
    bottom: 5%;
}

.tsb-img.tsb6{
    right: 10%;
    bottom: 5%;
}



/* --- @media Css Start --- */
@media screen and (max-width:900px) {
    .tsb-img.tsb3,
    .tsb-img.tsb4{
        display: none;
    }
}

@media screen and (max-width:576px) {
    .tsb-img.tsb1,
    .tsb-img.tsb2,
    .tsb-img.tsb5,
    .tsb-img.tsb6{
        display: none;
    }

    .testimonial-slider-bx{
        padding: 0;
    }

    .testimonial-slider-bx::after {
        top: -25px;
        transform: rotate(200deg);
    }

    .testimonial-slider-bx::before, .testimonial-slider-bx::after{
        width: 30px;
        height: 30px;
    }
}

/* --- @media Css End --- */