.mentor-card-item{
    cursor: pointer;
    /* border-radius: 16px; */
    overflow: hidden;
    /* padding: 10px 10px; */
    transition: all 0.3s ease-in-out;
    /* background-color: var(--white); */
    /* box-shadow: 0 0 0 1px var(--black-100); */
    backdrop-filter: blur(2px)
}

.mentor-card-item:hover{
    /* box-shadow: 0 0 20px -5px var(--black-300); */
}

.mentor-card-item .mci-img{
    position: relative;
    padding-top: 100%;
    border-radius: 16px;
    overflow: hidden;
}

.mentor-card-item .mci-img img{
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    object-position: top center;
    object-fit: cover;
    transition: all 0.3s ease-in-out;
    /* filter: grayscale(0.7); */
}

.mentor-card-item:hover .mci-img img{
    /* filter: grayscale(0); */
    transform: scale(1.05);
}

.mentor-card-item .nci-location,
.mentor-card-item .nci-subject,
.mentor-card-item .nci-name,
.mentor-card-item .nci-star{
    padding: 0 5px;
}

.mentor-card-item .nci-subject{
    font-size: 12px;
    font-weight: 500;
    color: var(--cp);
    margin: 10px auto 0;
    border-radius: 50px;
    width: fit-content;
    padding: 4px 12px;
    background: var(--cp-100);
}

.mentor-card-item:hover .nci-name{
    text-decoration: underline;
}

.mentor-card-item .nci-name{
    font-size: 18px;
    font-weight: 600;
    color: var(--black);
    margin-top: 8px;
    text-align: center;
}

.mentor-card-item .nci-star{
    position: absolute;
    right: 10px;
    top: 10px;
    width: fit-content;
    color: var(--green);
    background: var(--green-100);
    font-size: 12px;
    padding: 4px 12px;
    border-radius: 50px;
    font-weight: 600;
    display: flex;
    align-items: center;
    overflow: hidden;
    gap: 3px;
}

.mentor-card-item .nci-star svg{
    width: 15px;
    height: 15px;
    position: relative;
    top: -1px;
    color: var(--gold);
}

.mentor-card-item .nci-location{
    color: var(--textcolor);
    font-size: 14px;
    text-align: center;
    margin-top: 5px;
    font-weight: 500;
    font-size: 14px;
    line-height: 1.8;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    overflow: hidden;
}

.mentor-card-item .nci-location svg{
    width: 20px;
    height: 20px;
    margin-right: 2px;
    position: relative;
    top: -1px;
}
