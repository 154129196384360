/*
* Prefixed by https://autoprefixer.github.io
* PostCSS: v8.4.14,
* Autoprefixer: v10.4.7
* Browsers: last 120 version
*/

/* --- Login Page Back img --- */
.login-page-back-effect{
    position: fixed !important;
    top: 0;
    left: 0;
    /* background-image: url("../img/loginbg.jpg");
    background-position: center;
    background-repeat: no-repeat;
    -webkit-background-size: cover;
       -moz-background-size: cover;
         -o-background-size: cover;
            background-size: cover;
    background-attachment: fixed; */
    overflow: hidden;
    width: 100%;
    height: 100%;
    z-index: 0;
    -webkit-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}

.login-page-back-effect::after{
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    backdrop-filter: blur(1px);
    background-color: #f7f7f7;
}

/* --- login-separator --- */
.login-separator{
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    font-weight: 600;
    color: var(--textcolor);
    margin: 15px 0;
}

.login-separator::after{
    content: "";
    position: absolute;
    width: 100%;
    height: 2px;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    background-color: var(--black-100);
}

.login-separator span{
    position: relative;
    z-index: 1;
    display: inline-block;
    padding: 0 10px;
    background-color: var(--white);
}


/* --- Signinup header --- */
.signinup-header{
    position: relative;
    padding-left: 55px;
}

.signinup-header .back-btn{
    left: 0;
    top: 50%;
    transform: translateY(-50%);
}

.signinup-logo{
    display: block;
    width: 170px;
    position: relative;
    z-index: 1;
    margin: 10px auto 20px;
}


/* ---
Screen heading
--- */
.screen-hed {
    color: var(--black);
    font-weight: 600;
    font-size: 22px;
    margin-bottom: 10px;
}

.screen-hed span{
    color: var(--black);
}

.screen-pera {
    color: var(--textcolor);
    font-weight: 500;
    font-size: 14px;
    margin-top: -10px;
    margin-bottom: 20px;
}

/* --- Forget link Css --- */
.forget-link{
    width: fit-content;
    width: fit-content;
    margin-left: auto;
    display: block;
    font-size: 14px;
    font-weight: 500;
    padding: 10px 0;
    transition: all 0.3s ease-in-out;
    color: var(--textcolor);
}

.forget-link:hover{
    color: var(--cp);
    text-decoration: underline;
}

/* --- SignInUp Togle Link --- */

.signinuptogleblink{
    text-align: center;
    color: var(--textcolor);
    font-size: 14px;
    font-weight: 500;
    margin: 15px 0 5px;
}

.signinuptogleblink .siulink{
    margin-left: 5px;
    font-weight: 600;
    color: var(--cp);
    transition: all 0.3s ease-in-out;
}

.signinuptogleblink .siulink:hover{
    text-decoration: underline;
}

/* --- --- */
/* ---
Sign-In-Up Login Commun Btn
--- */

.login-commun-bx{
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    margin-bottom: 15px;
}

.login-commun-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5px;
    padding: 12px 15px;
    border-radius: 5px;
    width: 100%;
    font-weight: 600;
    color: #1c2b33;
    font-size: 14px;
    margin: 0px 0;
    position: relative;
    border: none;
    outline: none;
    cursor: pointer;
}

.login-commun-btn::after {
    content: "";
    position: absolute;
    left: 0px;
    top: 0px;
    height: 100%;
    width: 100%;
    border-radius: 5px;
    background-color: #0000001a;
    z-index: 1;
    opacity: 0;
}

.login-commun-btn svg {
    width: 20px;
    height: 20px;
    fill: currentColor;
    position: relative;
    z-index: 2;
}

.login-commun-btn span {
    position: relative;
    z-index: 2;
    display: block;
    top: 1px;
}

.login-commun-btn.facebook-btn {
    background-color: #2D88FF;
    color: #fff;
}

.login-commun-btn.google-btn {
    background-color: #ea4436;
    color: #fff;
}

.login-commun-btn:hover::after {
    opacity: 1;
}

/* --- Signinup Form Css --- */
.sign-in-up-bx {
    width: 100%;
    margin: auto;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
       -moz-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
       -moz-box-orient: vertical;
       -moz-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    padding: 10px 10px;
    min-height: -webkit-calc(100vh);
    min-height: -moz-calc(100vh);
    min-height: calc(100vh);
}

/* ---
Seconde Screen
--- */
.signinup-screen {
    max-width: 450px;
    width: 100%;
    position: relative;
    margin: auto;
    padding: 15px;
    display: none;
    background: white;
    border-radius: 15px;
    box-shadow: 0 0 20px -5px var(--black-300);
}

.signinup-screen.active{
    display: block;
}

.signinup-screen.mw768ss{
    max-width: 700px;
}

/* --- Sign In Up Site Info Css --- */
.sign-in-up-bx .site-info {
    font-size: 12px;
    color: rgb(191, 186, 196);
    text-align: center;
    margin-top: 10px;
    font-weight: 400;
}

.sign-in-up-bx .site-info span {
    display: inline-block;
    width: 12px;
}

.sign-in-up-bx .site-info a {
    display: inline-block;
    color: #f2f1f3;
    -webkit-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}

.sign-in-up-bx .site-info a:hover {
    text-decoration: underline;
}

/* --- Resend Otp Btn --- */
.resend-otp-btn{
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
       -moz-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
       -moz-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    padding: 8px 15px;
    -webkit-border-radius: 50px;
       -moz-border-radius: 50px;
            border-radius: 50px;
    width: 100%;
    font-weight: 400;
    color: var(--color3);
    background-color: transparent;
    border: 1px solid  var(--color3);
    font-size: 16px;
    margin: 15px 0;
    position: relative;
    cursor: pointer;
    -webkit-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
    letter-spacing: 0.5px;
}

.resend-otp-btn[disabled]{
    pointer-events: none;
    opacity: 0.5;
    color: #f2f1f3;
    background-color: rgba(32, 30, 35, 0.5);
    border: 1px solid rgba(255, 255, 255, 0.3);
}

.resend-otp-btn{
    margin-bottom: 20px;
}

.resend-otp-btn:hover{
    background-color: var(--color3);
    border-color: var(--color3);
    color: white;
}



@media screen and (max-width:576px) {
    
    .sign-in-up-bx {
        min-height: -webkit-calc(100vh - 120px);
        min-height: -moz-calc(100vh - 120px);
        min-height: calc(100vh - 120px);
        padding: 0;
    }

    .signinup-screen{
        margin-top: 0;
        max-width: 576px;
        padding-top: 25px;
        border-radius: 0;
        box-shadow: none;
    }

    .signinup-group.otp-group input{
        width: 40px;
        height: 40px;
        -webkit-box-flex: 0;
        -webkit-flex: 0 0 40px;
           -moz-box-flex: 0;
            -ms-flex: 0 0 40px;
                flex: 0 0 40px;
    }

    .screen-hed{
        font-size: 22px;
    }

    .login-page-back-effect::after{
        background-color: white;
    }

}

@media screen and (max-width:450px) {
    .signinup-screen{
       /* padding: 15px 5px; */
       padding-top: 25px;
    }

}

@media screen and (max-width:370px) {
    .edit-this{
        display: block;
        width: -webkit-fit-content;
        width: -moz-fit-content;
        width: fit-content;
        margin: 0;
        margin-top: 5px;
        margin-left: auto;
    }
}


.form-success-msg {
    display: block;
    padding: 10px;
    margin: 10px 0;
    color: #155724; /* Dark green color for success messages */
    background-color: #d4edda; /* Light green background for success messages */
    border: 1px solid #c3e6cb; /* Border color */
    -webkit-border-radius: 4px;
       -moz-border-radius: 4px;
            border-radius: 4px; /* Rounded corners */
  }