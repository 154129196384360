/* --- Web Loader Css Start --- */
.web-preloader {
    /* background-image: radial-gradient( var(--cp-100), var(--cp-100)); */
    background-color: #f7f7f7;
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    z-index: 2000;
}

.web-preloader span.web-inner {
    width: 250px;
    height: 250px;
    line-height: 250px;
    margin: auto;
    box-sizing: border-box;
    text-align: center;
    z-index: 0;
    text-transform: uppercase;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    color: var(--white);
    display: flex;
    justify-content: center;
    align-items: center;
}

.web-preloader span.web-inner img {
    position: relative;
    width: 100%;
    height: 100%;
    object-fit: contain;
    object-position: center;
}

.deactivate span.web-inner {
    opacity: 0;
    visibility: hidden;
    transform: translateY(50px);
    transition: all 0.3s 0.5s ease-in-out;
}

.deactivate {
    opacity: 0;
    visibility: hidden;
    transition: all 0.3s 1s ease-in-out;
}
/* --- Web Loader Css End --- */