html{
    scroll-behavior: initial !important;
}

*{
    text-decoration: none;
    box-sizing: border-box;
}

a,a:hover{
    text-decoration: none;
    color: var(--black);
}


/* Common colors */
:root{
    --white: #ffffff;
    --white-900: rgba(255, 255, 255, 0.9);
    --white-800: rgba(255, 255, 255, 0.8);
    --white-700: rgba(255, 255, 255, 0.7);
    --white-600: rgba(255, 255, 255, 0.6);
    --white-500: rgba(255, 255, 255, 0.5);
    --white-400: rgba(255, 255, 255, 0.4);
    --white-300: rgba(255, 255, 255, 0.3);
    --white-200: rgba(255, 255, 255, 0.2);
    --white-100: rgba(255, 255, 255, 0.1);

    --black: #000000;
    --black-900: rgba(0, 0, 0, 0.9);
    --black-800: rgba(0, 0, 0, 0.8);
    --black-700: rgba(0, 0, 0, 0.7);
    --black-600: rgba(0, 0, 0, 0.6);
    --black-500: rgba(0, 0, 0, 0.5);
    --black-400: rgba(0, 0, 0, 0.4);
    --black-300: rgba(0, 0, 0, 0.3);
    --black-200: rgba(0, 0, 0, 0.2);
    --black-100: rgba(0, 0, 0, 0.1);

    --cp: #FF0066;
    --cp-100: #fff2f7;

    --textcolor: #525252;
    
    /* --cs: #E2667C; */
    --ct: #63B1B9;
    --cf: #fbfdff;

    /* --gold: #d2af6d; */
    --gold: var(--black);

    /* --green: #4CAF50; */
    --green: var(--black);

    /* --green-100: #e9f5ed; */
    --green-100:  #f7f7f7;

    
    --blue: var(--black);
    /* --blue-900: rgba(88, 154, 240, 0.9); */
    /* --blue-800: rgba(88, 154, 240, 0.8); */
    /* --blue-700: rgba(88, 154, 240, 0.7); */
    /* --blue-600: rgba(88, 154, 240, 0.6); */
    /* --blue-500: rgba(88, 154, 240, 0.5); */
    /* --blue-400: rgba(88, 154, 240, 0.4); */
    /* --blue-300: rgba(88, 154, 240, 0.3); */
    /* --blue-200: rgba(88, 154, 240, 0.2); */
    /* --blue-100: rgba(88, 154, 240, 0.1); */

}

/* --- Common Scrollbar Hide --- */
.commonscrollbarhide::-webkit-scrollbar{
    display: none;
}


/* --- Website Fonts --- */
@font-face {
    font-family: 'FS-Elliot';
    src: url("../fonts/FS-Elliot-ProThin.otf");
    font-weight: 100;
}

@font-face {
    font-family: 'FS-Elliot';
    src: url("../fonts/FS-Elliot-ProLight.otf");
    font-weight: 300;
}

@font-face {
    font-family: 'FS-Elliot';
    src: url("../fonts/FS-Elliot-ProRegular.otf");
    font-weight: 400;
}

@font-face {
    font-family: 'FS-Elliot';
    src: url("../fonts/FS-Elliot-ProBold.otf");
    font-weight: 800;
}

@font-face {
    font-family: 'FS-Elliot';
    /* src: url("../fonts/FS-Elliot-ProHeavy.otf"); */
    src: url("../fonts/FS-Elliot-ProBold.otf");
    font-weight: 900;
}


/* --- Body tag Css --- */
body{
    padding: 0;
    margin: 0;
    font-family: 'FS-Elliot';
    font-weight: 400;
    font-size: 16px;
    color: var(--black);
    background-color: var(--white);
    overflow: hidden;
    overflow-y: auto;
}

body.overflowhidden{
    overflow: hidden;
}


.relative{
    position: relative;
}

/* --- main-wrapper --- */
.main-wrapper{
    min-height: calc(100vh - 66px);
}


/* --- Common max width --- */
.maxwidth1920{
    max-width: 1920px;
    margin: auto;
}


/* --- Bootsrep Overaid Css Start --- */
.container-lg.cl-custome{
    max-width: 1140px;
}

.modal{
    backdrop-filter: blur(5px);
}

.modal.fade.zoom .modal-dialog{
    transform: scale(0.9);
}

.modal.zoom.show .modal-dialog {
    transform: none;
}

.modal-content.custom-content{
    border-radius: 16px;
}

.custom-modal-header{
    position: sticky;
    padding: 20px 15px 15px;
}

.custom-modal-header .cmh-lable{
    font-size: 20px;
    color: var(--black);
    font-weight: 600;
    text-align: center;
}

.custom-modal-header .cmh-sub-lable{
    font-size: 16px;
    color: var(--textcolor);
    font-weight: 500;
    text-align: center;
    margin-top: 5px;
}


/* --- Overlaydiv Css Start --- */
.checkout-overlay,
.serach-overlay,
.overlaydiv {
    position: fixed;
    width: 100vw;
    height: 100vh;
    background: var(--black-300);
    opacity: 0;
    visibility: hidden;
    transition:all 0.3s ease-in-out;
    top: 0;
    cursor: pointer;
    margin: 0 !important;
    z-index: 110;
    backdrop-filter: blur(5px);
}

.serach-overlay.active,
.overlaydiv.active {
    visibility: visible;
    opacity: 1;
}
/* --- Overlaydiv Css End --- */


/* --- Close Btn Css Start --- */
.close-icon{
    position: absolute;
    right: 10px;
    top: 10px;
    width: 35px;
    height: 35px;
    background: transparent;
    border-radius: 100%;
    display: flex;
    color: var(--black);
    padding: 4px;
    cursor: pointer;
    transition: 0.3s ease-in-out;
}

.close-icon:hover{
    transform: rotate(360deg);
}

@media screen and (min-width:700px) {
    .close-icon:hover{
        background: var(--black-100);
    }
}

@media screen and (max-width:700px) and (min-width:0px) {
    .close-icon:active{
        background: var(--black-100);
    }
}
/* --- Close Btn Css End --- */

/* --- Back Btn Css Start --- */
.back-btn{
    position: absolute;
    left: 15px;
    top: 15px;
    color: var(--black);
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    flex: 0 0 40px;
    border-radius: 50%;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
    background-color: var(--black-100);
}

.back-btn:hover{
    /* color: var(--white); */
    background-color: var(--black-100);
}

.back-btn svg{
    width: 20px;
    height: 20px;
    position: relative;
    left: -1px;
    color: currentColor;
}
/* --- Back Btn Css Start --- */


/* --- Common Heading Bx Css Start --- */
.common-heading-bx{
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 5px;
}

.common-heading-bx.chb-border-bottom{
    padding-bottom: 10px;
    border-bottom: 1px solid var(--black-100);
}

.common-heading-bx.chb-fd-row{
    gap: 15px;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-end;
}

.common-heading-bx .common-top-heading{
    font-size: 14px;
    color: var(--cp);
    font-weight: 600;
}

.common-heading-bx .common-heading{
    font-size: 28px;
    color: var(--black);
    font-weight: 600;
}

.common-heading.common-heading-small{
    font-size: 20px;
}

.common-heading-bx .common-heading .ch-color{
    color: var(--cp);
}

.common-heading-bx .common-heading .ch-color-gradient{
    background: linear-gradient(267.75deg, var(--black), var(--blue));
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.common-heading-bx .common-heading .ch-size-big{
    font-size: 140%;
}

.common-heading-bx .common-heading span:not(.ch-color){
    color: var(--cp);
    font-size: 14px;
}

.common-heading-bx .common-sub-heading{
    font-size: 16px;
    font-weight: 500;
    color: var(--textcolor);
}

.common-heading-bx .common-sub-heading svg{
    width: 20px;
    height: 20px;
    margin-right: 3px;
    position: relative;
    top: -1px;
    color: var(--cp);
}

@media screen and (max-width:768px) {
    .common-heading-bx .common-sub-heading br,
    .common-heading br{
        display: none;
    }

    .common-heading-bx.chb-fd-row{
        flex-direction: column;
        justify-content: inherit;
        /* align-items: inherit; */
    }
}

@media screen and (max-width:768px) {
    .common-heading-bx .common-heading{
        font-size: 20px;
    }

    .common-heading-bx .common-sub-heading{
        font-size: 14px;
    }
}


.chb-two{
    border-bottom: 1px solid var(--black-100);
    padding-bottom: 10px;
}

.chb-two .common-heading{
    font-size: 18px;
}

.chb-two .common-sub-heading{
    margin-top: -5px;
}


/* --- common date style css start --- */

.common-img-bx img{
    max-width: 400px;
    width: 100%;
    padding: 0 25px 40px;
    margin: auto;
    display: block;
}

.common-date-text{
    font-size: 16px;
    color: var(--black);
    font-weight: 400;
}

.common-date-ul{
    color: var(--celeste-600);
    padding: 0;
    list-style: none;
}

.common-date-ul li{
    position: relative;
    color: var(--textcolor);
    padding: 5px 0px;
    padding-left: 20px;
    font-size: 16px;
    font-weight: 500;
}

.common-date-ul li::after{
    content: "";
    position: absolute;
    left: 2px;
    top: 13px;
    width: 8px;
    height: 8px;
    background-color: var(--textcolor);
    border-radius: 50%;
}

.common-date-ul.cdu-flex{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.common-date-ul.cdu-no-style li::after{
    content: none;
}
/* --- common date style css end --- */


/* --- Common Bg Color Start --- */
.bg-color-lightgray{
    background-color: #f7f7f7;
}

.bg-color-e6{
    background-color: #e6e6e6;
}

.bg-img-after::after{
    content: "";
    position: absolute;
    bottom: 0;
    height: 100%;
    width: 100%;
    background-image: url("../img/footer-bg.png");
    background-position: center bottom;
    background-repeat: no-repeat;
    background-size: contain;
    opacity: .8;
    z-index: -1;
}

.bg-img-after.pettan1::after{
    background-image: url("../img/pettan.png");
    background-size: 150%;
    background-position: center left;
    width: 50%;
    right: 0;
}

@media (max-width:576px) {
    .bg-img-after.pettan1::after{
        background-size: 200%;
        background-position: left bottom;
        width: 90%;
    }
}
/* --- Common Bg Color End --- */


/* --- Common Button Css Start --- */
.common-btn-item{
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 54px;
    height: 40px;
    padding: 0 25px;
    font-size: 14px;
    width: -moz-fit-content;
    width: fit-content;
    color: var(--black);
    font-weight: 600;
    border: 1px solid transparent;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
    overflow: hidden;
}

.common-btn-item.cb-big{
    height: 45px;
}

.common-btn-item.cb-small{
    padding: 0 14px;
    height: 35px;
}

.common-btn-item.cbi-delete{
    height: 24px;
    width: 24px;
    padding: 0 0px;
    font-weight: 500;
    font-size: 12px;
}

.common-btn-item::after{
    content: "";
    position: absolute;
    inset: 0;
    background-image: linear-gradient(transparent, var(--black-600));
    border-radius: 54px;
    opacity: 0;
    visibility: hidden;
    transition: all 0.3s ease-in-out;
}

.common-btn-item span{
    position: relative;
    z-index: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    transition: all 0.3s ease-in-out;
}

.common-btn-item img{
    position: relative;
    top: 0.5px;
    width: 18px;
    height: 18px;
    object-position: center;
    object-fit: contain;
    margin-left: 3px;
    transition: all 0.3s ease-in-out;
}


/* Fill Btn */
.common-btn-item.cbi-fill{
    color: var(--white);
    background: var(--black);
}

.common-btn-item.cbi-fill:hover{
    background: var(--cp);
}

/* .common-btn-item.cbi-fill:hover::after{
    opacity: 1;
    visibility: visible;
} */


/* Outline btn */
.common-btn-item.cbi-outline{
    border: 1px solid var(--black);
    color: var(--black);
}

.common-btn-item.cbi-outline:hover{
    border-color: var(--cp);
}


.common-btn-item.cbi-outline::before{
    content: attr(cbdata);
    position: absolute;
    inset: 0px;
    color: var(--white);
    background: var(--cp);
    z-index: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    transform: translateX(calc(-100% - 10px));
    transition: all 0.3s ease-in-out;
}

.common-btn-item.cbi-outline:hover span{
    transform: translateX(calc(100% + 40px));
}

.common-btn-item.cbi-outline:hover::before{
    transform: translateX(0);
}

/* --- Common Button Css End --- */


/* --- Slider Item Outer Start --- */
.slider-item-outer{
    padding: 10px 8px;
}
/* --- Slider Item-Outer End --- */

/* --- Silke Btn Css Start --- */
.slick-arrow{
    width: 35px;
    height: 35px;
    border: none;
    background-color:  var(--cp-100);
    border-radius: 50%;
    font-size: 24px;
    display: inline-flex !important;
    justify-content: center;
    align-items: center;
    margin: 0;
    border: 1.5px solid var(--cp);
    opacity: 0.7;
    padding: 5px;
    overflow: hidden;
    color: transparent;
    z-index: 1;
    transition: all 0.3s ease-in-out;
}

.slick-arrow::after{
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
    background-size: 75% 75%;
    border-radius: 50%;
}

.slick-arrow.slick-next.slick-disabled,
.slick-arrow.slick-prev.slick-disabled {
    opacity: 0.2 !important;
    visibility: visible !important;
    pointer-events: none;
}

.slick-arrow.slick-prev {
    position: absolute;
    right: 40px;
    top: -40px;
}

.slick-arrow.slick-prev::after {
    background-position: 4px center;
    background-image: url("../img/icons/arrowright.svg");
    transform: rotate(180deg);
}

.slick-arrow.slick-next {
    position: absolute;
    right: 0px;
    top: -40px;
}

.slick-arrow.slick-next::after {
    background-position: 4px center;
    background-image: url("../img/icons/arrowright.svg");
}

.slick-arrow:hover{
    opacity: 1;
    background-color: transparent;
    border-color: var(--cp);
}
/* --- Silke Btn Css End --- */


/* --- Slick Dots Start --- */
.slick-dots{
    display: flex !important;
    justify-content: center;
    padding: 0;
    margin: 0;
    margin: 5px 0px;
    padding: 0 8px;
    position: absolute;
    left: 0;
    width: 100%;
    bottom: 10px;
    z-index: 1;
}

.slick-dots li button{
    display: none;
}

.slick-dots li{
    list-style: none;
    width: 7px;
    height: 7px;
    background-color: var(--color4);
    margin: 2px;
    border-radius: 50px;
    transition:all 0.3s ease-in-out;
}

.slick-dots li.slick-active{
    background-color: var(--white);
    width: 14px;
}
/* --- Slick Dots End --- */


/* --- Empty Box Css start --- */
.empty-div-bx {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin: 20px 0;
    width: 100%;
    min-height: 220px;
    padding: 30px 10px;
    gap: 10px;
}

.empty-div-bx .empty-img {
    width: 70px;
    display: block;
}

.empty-div-bx .empty-heading {
    color: var(--black);
    font-weight: 600;
    font-size: 24px;
    text-align: center;
    transition: all 0.3s ease-in-out;
}

.empty-div-bx .empty-sub-heading {
    color: var(--textcolor);
    font-weight: 500;
    font-size: 16px;
    text-align: center;
    transition: all 0.3s ease-in-out;
}

.empty-div-bx .empty-btn {
    color: var(--black);
    padding: 6px 15px;
    background-color: var(--black-100);
    border-radius: 50px;
    cursor: pointer;
    font-size: 14px;
    font-weight: 600;
    transition: all 0.3s ease-in-out;
}

.empty-div-bx .empty-btn:hover {
    color: var(--white);
    background-color: var(--cp);
}
/* --- Empty Box Css End--- */


/* --- How it works Start --- */
.works-card-item{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 10px;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
}

.works-card-item .wci-img{
    position: relative;
    z-index: 2;
    transition: all 0.3s ease-in-out;
}

/* .works-card-item .wci-img::after{
    content: "";
    position: absolute;
    left: 50%;
    top: 50%;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    transform: translate(-50%, -50%) scale(0);
    transition: all 0.3s ease-in-out;
    z-index: -1;
    opacity: 0;
}

.works-card-item:hover .wci-img::after{
    opacity: 1;
    transform: translate(-50%, -50%) scale(1.2);
}

.works-card-item .wci-img.wci-color1::after{
    box-shadow: 0 0 0px 2px #21b8f5;
}

.works-card-item .wci-img.wci-color2::after{
    box-shadow: 0 0 0px 2px #b443ff;
}

.works-card-item .wci-img.wci-color3::after{
    box-shadow: 0 0 0px 2px #86288e;
} */

.works-card-item .wci-img img{
    width: 70px;
    height: 70px;
    object-fit: contain;
    object-position: center;
    border-radius: 50px;
    box-shadow: 0 0 30px -10px var(--black-300);
}

.works-card-item .wci-heading{
    font-size: 20px;
    font-weight: 600;
    color: var(--black);
    text-align: center;
}

.works-card-item .wci-text{
    font-size: 14px;
    font-weight: 500;
    color: var(--textcolor);
    text-align: center;
}

/* --- How it works End --- */



/* --- Lagal pages css start --- */
.legal-content .main-lable {
    font-weight: 600;
    margin-top: 30px;
    color: var(--black);
    font-size: 20px;
    text-transform: capitalize;
}

.legal-content .main-sub-lable {
    margin-top: 30px;
    font-weight: 600;
    color: var(--black);
    font-size: 18px;
    text-transform: capitalize;
    margin-bottom: 0px;
}

.legal-content ul li,
.legal-content p {
    font-size: 15px;
    line-height: 1.5;
    margin: 0;
    margin-top: 10px;
    color: var(--textcolor);
    font-weight: 500;
    text-align: justify;
}

.legal-content ul {
    list-style: decimal;
    padding: 0 0 0 10px;
    margin-left: 20px;
    margin-top: 10px;
}

.legal-content ul.ul-dick {
    list-style: disc;
}

.legal-content ul.ul-alpha {
    list-style: lower-alpha;
}

.legal-content ul.ul-flex {
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-wrap: wrap;
        -ms-flex-wrap: wrap;
            flex-wrap: wrap;
}

.legal-content ul.ul-flex li {
    width: 25%;
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 auto;
       -moz-box-flex: 1;
        -ms-flex: 1 1 auto;
            flex: 1 1 auto;
    padding-right: 30px;
    text-align: left;
}

@media screen and (max-width:992px) {
    .legal-content ul.ul-flex li {
        width: 33.33%;
    }
}

@media screen and (max-width:768px) {
    .legal-content ul.ul-flex li {
        width: 50%;
    }
}

@media screen and (max-width:450px) {
    .legal-content ul.ul-flex li {
        width: 100%;
        padding-right: 0px;
    }
}


.legal-content ul li a,
.legal-content p a {
    text-decoration: underline;
    color: var(--black);
}

.legal-content ul a:hover,
.legal-content p a:hover {
    color: var(--cp);
}

.legal-content ul strong,
.legal-content p strong {
    color: var(--black);
}

/* --- Lagal pages css end --- */


/* --- Contact Us page --- */
.contact-info {
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
       -moz-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    padding: 10px 10px;
    margin: 10px 0 0;
    cursor: pointer;
    -webkit-border-radius: 10px;
       -moz-border-radius: 10px;
            border-radius: 10px;
    border: 1px solid var(--black-100);
    -webkit-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}

.contact-info:hover {
    border-color: var(--cp-100);
    background-color: var(--cp-100);
}

.contact-info .contact-icon {
    padding: 2px;
    width: 40px;
    height: 40px;
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 40px;
       -moz-box-flex: 0;
        -ms-flex: 0 0 40px;
            flex: 0 0 40px;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
       -moz-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
       -moz-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    margin-right: 10px;
    -webkit-border-radius: 10px;
       -moz-border-radius: 10px;
            border-radius: 10px;
    -webkit-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
    opacity: 0.5;
}

.contact-info:hover .contact-icon {
    opacity: 1;
}

.contact-info .contact-icon img {
    width: 100%;
}

.contact-text-lable,
.contact-info .contact-text h6 {
    color: var(--black);
    font-weight: 600;
    font-size: 16px;
    line-height: 1.73;
    overflow: hidden;
    margin: 0;
    -webkit-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}

.contact-info .contact-text p {
    color: var(--textcolor);
    font-size: 14px;
    margin: 0;
    font-weight: 500;
}

.contactform{
    padding: 20px 20px;
    -webkit-border-radius: 16px;
       -moz-border-radius: 16px;
            border-radius: 16px;
    background-color: var(--cf);
    border: 1px solid var(--black-100);
}


/* --- Sitemap Page Css --- */
.sitemaplinks-bx {
    padding: 10px 10px 10px 10px;
    position: relative;
    margin-bottom: 40px;
}

.sitemap-heding {
    position: relative;
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 15px;
    color: var(--black);
    display: block;
    padding-bottom: 10px;
}

.sitemap-heding::before,
.sitemap-heding::after {
    content: "";
    position: absolute;
    left: 0;
    top: 100%;
    width: 20px;
    height: 1px;
    -webkit-border-radius: 5px;
       -moz-border-radius: 5px;
            border-radius: 5px;
    background-color: var(--black-100);
}

.sitemap-heding::before {
    left: inherit;
    right: 0;
    top: 100%;
    width: -webkit-calc(100% - 30px);
    width: -moz-calc(100% - 30px);
    width: calc(100% - 30px);
    background-color: var(--black-100);
}

.sitemap-ul {
    list-style: none;
    margin: 0;
    padding: 0;
}

.sitemap-ul li {
    width: 19%;
    display: inline-block;
    margin: 4px 0;
}

.sitemap-ul li .sitemap-link {
    cursor: pointer;
    display: -webkit-inline-box;
    display: -webkit-inline-flex;
    display: -moz-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
       -moz-box-pack: start;
        -ms-flex-pack: start;
            justify-content: flex-start;
    -webkit-box-align: center;
    -webkit-align-items: center;
       -moz-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    height: 30px;
    font-size: 16px;
    -webkit-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
    text-transform: capitalize;
    color: var(--textcolor);
    font-weight: 500;
}

.sitemap-ul li .sitemap-link svg {
    margin-right: 3px;
    width: 16px;
    height: 16px;
    -o-object-fit: contain;
       object-fit: contain;
    -o-object-position: center;
       object-position: center;
    -webkit-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}

.sitemap-ul li .sitemap-link:hover {
    color: var(--cp);
    text-decoration: underline;
}

@media screen and (max-width:992px) {
    .sitemap-ul li {
        width: 24%;
    }
}

@media screen and (max-width:768px) {
    .sitemap-ul li {
        width: 32%;
    }
}

@media screen and (max-width:576px) {
    .sitemap-ul li {
        width: 49%;
    }
}
/* --- Sitemap page css end --- */



/* --- Add Reviews bx start --- */

.add-reviews-bx{
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
}

.add-reviews-bx::after{
    content: "";
    position: absolute;
    left: 50%;
    height: 100%;
    width: 1px;
    background-color: var(--black-100);
}

.add-reviews-bx .arb-right,
.add-reviews-bx .arb-left{
    width: 250px;
    text-align: center;
    padding: 0 5px;
}

.add-reviews-bx .arb-left p{
    font-size: 14px;
    color: var(--textcolor);
    font-weight: 500;
    margin-top: 5px;
    margin-bottom: 0;
}


.star-rating{
    display: flex;
    gap: 1px;
    padding: 4px 0 0 5px;
}

.star-rating .star-icon{
    cursor: pointer;
    color: #bfbac4;
}

.star-rating .star-icon svg{
    width: 24px;
    height: 24px;
    color: currentColor;
}

.star-rating .star-icon.filled{
    color: var(--gold);
}

@media screen and (max-width:576px) {
    .add-reviews-bx .arb-right,
    .add-reviews-bx .arb-left{
        width: 50%;
    }
}

@media screen and (max-width:370px) {
    .add-reviews-bx{
        flex-direction: column;
    }

    .add-reviews-bx::after{
        content: none;
    }

    .add-reviews-bx .arb-right,
    .add-reviews-bx .arb-left{
        width: 100%;
    }

    .add-reviews-bx .arb-right{
        margin-top: 15px;
    }
}

/* --- Add Reviews bx End --- */



/* =============================
    order page success start
================================ */

.processing {
    padding: 30px 15px;
}


.processing .pro-img-bx{
    width: 180px;
    height: 180px;
    margin: 10px auto;
    display: block;
}

.processing .p-order-id {
    font-weight: 600;
    font-size: 18px;
    margin-bottom: 15px;
    text-align: center;
    color: var(--black);
}

.processing .p-order-text{
    text-align: center;
    font-size: 16px;
    line-height: 1.5;
    margin: 0;
    margin-top: 5px;
    color: var(--textcolor);
    font-weight: 600;
}




/* ---- */

.top-top-heading{
    font-size: 24px;
    color: black;
    font-weight: 800;
    padding: 20px 0;
}

.font40px{
    font-size: 40px;
}
.wg300{
    font-weight: 300;
}

.top-section{
    background-image: url('../img/banner-04.jpg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.top-section.bg-img-two{
    background-image: url('../img/banner-05.jpg');
}

.top-section.bg-img-three{
    background-image: url('../img/banner-03.jpg');
}

.top-section.no-img-white{
    background-image: none;
    background-color: white;
}

.top-section.no-img-e6{
    background-image: none;
    background-color: #e6e6e6;
}

.top-section .top-heading{
    font-size: 40px;
    color: white;
    font-weight: 900;
}

.data-div-bx{
    background-color: white;
    border-radius: 15px;
    padding: 15px;
    height: 100%;
}

.data-div-bx > div:nth-child(1){
    font-size: 14px;
    font-weight: 800;
    margin-bottom: 10px;
}

.data-div-bx > div:nth-child(2){
    font-size: 14px;
    font-weight: 400;
    margin-bottom: 10px;
}


.secdkhgbkjrs{
    font-size: 22px;
    font-weight: 800;
}


.vdhvbs{
    font-size: 24px;
    font-weight: 800;
    color: var(--white);
    margin-bottom: 15px;
}

.safjehbgfvehjb{
    display: block;
    max-width: 288px;
    width: 100%;
    background-color: white;
    padding: 10px 10px;
    text-align: center;
    border-radius: 5px;
    font-size: 20px;
    font-weight: 400;
    margin-bottom: 15px;
    transition: all 0.3s ease-in-out;
    cursor: pointer;
}

.safjehbgfvehjb.radio-50px{
    border-radius: 50px;
}

.style-two .safjehbgfvehjb,
.safjehbgfvehjb:hover{
    color: var(--white);
    background-color: var(--cp);
}

.csdhvbsdk{
    font-size: 14px;
    font-weight: 400;
    color: var(--white);
    max-width: 288px;
    width: 100%;
    text-align: justify;
}

.style-two .csdhvbsdk,
.style-two .vdhvbs{
    color:black;
}


.cedzegrdb{
    background-color: white;
    padding: 15px 10px 0 10px;
    border-radius: 15px 15px;
    overflow: hidden;
    display: flex;
    align-items:  flex-end;
    box-shadow: 5px 5px 15px -10px black;
}

.cedzegrdb > div{
    padding-left: 10px;
}

.cedzegrdb img{
    width: 80px;
    flex: 0 0 80px;
    height: fit-content;
    /* border-radius: 15px 15px 0 0; */
    overflow: hidden;
}

.cedzegrdb .syucgdavs{
    color: #6f6f6f;
    font-size: 16px;
    font-weight: 800;
}

.cedzegrdb .syucgdavs-textbc{
    color: #6f6f6f;
    font-size: 14px;
    font-weight: 400;
    margin-top: 15px;
    margin-bottom: 15px;
}


@media screen and (max-width:992px) {
    .top-section .top-heading{
        font-size: 32px;
    }

    .top-top-heading.font40px{
        font-size: 36px;
    }
}

@media screen and (max-width:768px) {
    .top-section .top-heading{
        font-size: 28px;
    }

    .top-top-heading{
        font-size: 20px;
    }

    .top-top-heading.font40px{
        font-size: 32px;
    }
}

@media screen and (max-width:680px) {
    .top-top-heading.font40px{
        font-size: 28px;
    }
}

@media screen and (max-width:576px) {
    .top-section .top-heading{
        font-size: 24px;
        text-align: center;
    }

    .top-top-heading.font40px{
        font-size: 24px;
    }

    
    .top-top-heading{
        text-align: center;
    }

    .top-top-heading br{
        display: none;
    }


    .vdhvbs,
    .safjehbgfvehjb,    
    .csdhvbsdk{
        text-align: center;
        margin-left: auto;
        margin-right: auto;
    }
}

@media screen and (max-width:360px) {

    .top-section .top-heading br{
        display: none;
    }
}
