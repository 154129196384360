/*
* Prefixed by https://autoprefixer.github.io
* PostCSS: v8.4.14,
* Autoprefixer: v10.4.7
* Browsers: last 120 version
*/

.account-main-section{
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    width: 100%;
    max-width: 1920px;
    height: 100%;
    margin: 0 auto;
    background: #f7f7f7;
}

.account-right .breadcrumb-outer{
    transform: translateX(-15px);
    width: calc(100% + 30px);
    position: relative;
    z-index: 0;
}

/* --- Account Left --- */
.account-left{
    position: -webkit-sticky;
    position: sticky;
    top: 66px;
    left: 0;
    height: -webkit-calc(100vh - 66px);
    height: -moz-calc(100vh - 66px);
    height: calc(100vh - 66px);
    overflow: hidden;
    overflow-y: auto;
    -webkit-box-flex: 0;
    -webkit-flex: 0 1 300px;
       -moz-box-flex: 0;
        -ms-flex: 0 1 300px;
            flex: 0 1 300px;
    width: 300px;
    background-color: var(--cf);
    -webkit-box-shadow: 0px 4px 25px rgba(0, 0, 0, 0.08);
       -moz-box-shadow: 0px 4px 25px rgba(0, 0, 0, 0.08);
            box-shadow: 0px 4px 25px rgba(0, 0, 0, 0.08);
    padding-bottom: 50px;
    z-index: 1;
}


/* --- Profile left pic --- */
.account-left-pic{
    position: relative;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
       -moz-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    padding: 15px;
    white-space: nowrap;
    width: 100%;
    background: var(--cf);
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 2;
}

.account-left-pic::after{
    content: " ";
    position: absolute;
    left: 50%;
    bottom: 0;
    width: -webkit-calc(100% - 20px);
    width: -moz-calc(100% - 20px);
    width: calc(100% - 20px);
    -webkit-transform: translateX(-50%);
       -moz-transform: translateX(-50%);
        -ms-transform: translateX(-50%);
         -o-transform: translateX(-50%);
            transform: translateX(-50%);
    height: 1px;
    background-color:var(--black-100);
}

.account-left-pic .alp-img{
    width: 45px;
    height: 45px;
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 45px;
       -moz-box-flex: 0;
        -ms-flex: 0 0 45px;
            flex: 0 0 45px;
    -webkit-border-radius: 50%;
       -moz-border-radius: 50%;
            border-radius: 50%;
    margin-right: 8px;
    object-position: center;
    object-fit: cover;
}

.account-left-pic svg{
    width: 45px;
    height: 45px;
    -o-object-position: center;
       object-position: center;
    -o-object-fit: contain;
       object-fit: contain;
    padding: 10px;
    margin-right: 10px;
    -webkit-border-radius: 50px;
       -moz-border-radius: 50px;
            border-radius: 50px;
    background: var(--black-100);
    border: 1px solid var(--black-100);
}

.account-left-pic .alp-data .alp-name-lable{
    font-size: 14px;
    font-weight: 600;
    color: var(--black);
    white-space: nowrap;
    overflow: hidden;
    -o-text-overflow: ellipsis;
       text-overflow: ellipsis;
}

.account-left-pic .alp-data .alp-email-lable{
    color: var(--textcolor);
    font-size: 12px;
    font-weight: 500;
    white-space: nowrap;
    overflow: hidden;
    -o-text-overflow: ellipsis;
       text-overflow: ellipsis;
}

/* --- Side menu --- */
.smb-menu-bx .account-left-pic{
    position: static;
    background-color: var(--color1);
    opacity: 0;
    visibility: hidden;
    -webkit-transform: translateY(20px);
       -moz-transform: translateY(20px);
        -ms-transform: translateY(20px);
         -o-transform: translateY(20px);
            transform: translateY(20px);
    -webkit-transition: 0.3s all 0.55s ease;
    -o-transition: 0.3s all 0.55s ease;
    -moz-transition: 0.3s all 0.55s ease;
    transition: 0.3s all 0.55s ease;
}

.side-menu-bx.active .account-left-pic{
    opacity: 1;
    visibility: visible;
    -webkit-transform: translateY(0px);
       -moz-transform: translateY(0px);
        -ms-transform: translateY(0px);
         -o-transform: translateY(0px);
            transform: translateY(0px);
}

.side-menu-bx.active .account-left-pic:hover svg{
    color: var(--cp);
    border-color: var(--cp) ;
    background-color: var(--cp-100);
}
/* --- Side Menu --- */

/* --- Checkout Pic --- */
.checkout-pic.account-left-pic{
    background: none;
    padding: 0px;
    position: static;
}

.checkout-pic.account-left-pic::after{
    content: none;
}
/* --- Checkout Pic --- */


/* --- Account menu bx --- */
.account-menu-bx{
    padding-left: 10px;
    margin-top: 15px;
}

.account-menu-bx .amb-link{
    position: relative;
    display: block;
    height: 44px;
    line-height: 44px;
    padding: 0 15px;
    font-size: 15px;
    margin-bottom: 3px;
    color: var(--black);
    font-weight: 500;
    text-transform: capitalize;
    cursor: pointer;
    -webkit-border-radius: 8px 0 0 8px;
       -moz-border-radius: 8px 0 0 8px;
            border-radius: 8px 0 0 8px;
    -webkit-transition: background-color 0.3s ease-in-out;
    -o-transition: background-color 0.3s ease-in-out;
    -moz-transition: background-color 0.3s ease-in-out;
    transition: background-color 0.3s ease-in-out;
}

.account-menu-bx .amb-link svg{
    position: relative;
    top: -1px;
    width: 20px;
    height: 20px;
    -o-object-position: center;
       object-position: center;
    -o-object-fit: contain;
       object-fit: contain;
    margin-right: 10px;
}

.account-menu-bx .amb-link:hover,
.account-menu-bx .amb-link.active{
    background-color: var(--cp-100);
}

.account-menu-bx .amb-link::after{
    content: "";
    position: absolute;
    right: 0;
    top: 50%;
    -webkit-transform: translateY(-50%);
       -moz-transform: translateY(-50%);
        -ms-transform: translateY(-50%);
         -o-transform: translateY(-50%);
            transform: translateY(-50%);
    width: 4px;
    height: -webkit-calc(100% - 10px);
    height: -moz-calc(100% - 10px);
    height: calc(100% - 10px);
    background-color: var(--cp);
    /* background-image: -webkit-linear-gradient(135deg, var(--color7), var(--color6));
    background-image: -moz-linear-gradient(135deg, var(--color7), var(--color6));
    background-image: -o-linear-gradient(135deg, var(--color7), var(--color6));
    background-image: linear-gradient(-45deg, var(--color7), var(--color6)); */
    -webkit-border-radius: 50px 0 0 50px;
       -moz-border-radius: 50px 0 0 50px;
            border-radius: 50px 0 0 50px;
    opacity: 0;
    -webkit-transition: opacity 0.3s ease-in-out;
    -o-transition: opacity 0.3s ease-in-out;
    -moz-transition: opacity 0.3s ease-in-out;
    transition: opacity 0.3s ease-in-out;
}

.account-menu-bx .amb-link.active::after{
    opacity: 1;
}

.account-menu-bx .amb-link .amb-num{
    position: absolute;
    left: 24px;
    top: 5px;
    z-index: 1;
    width: 17px;
    height: 17px;
    -webkit-border-radius: 50px;
       -moz-border-radius: 50px;
            border-radius: 50px;
    background-color: var(--cp);
    /* background-image: -webkit-linear-gradient(135deg, var(--color7), var(--color6));
    background-image: -moz-linear-gradient(135deg, var(--color7), var(--color6));
    background-image: -o-linear-gradient(135deg, var(--color7), var(--color6));
    background-image: linear-gradient(-45deg, var(--color7), var(--color6)); */
    display: block;
    color: white;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
       -moz-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
       -moz-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    font-weight: 400;
    font-size: 11px;
}

/* --- Account Right --- */
.account-right{
    -webkit-box-flex: 1;
    -webkit-flex: 1 0 -webkit-calc(100% - 300px);
       -moz-box-flex: 1;
        -ms-flex: 1 0 calc(100% - 300px);
            flex: 1 0 calc(100% - 300px);
    width: -webkit-calc(100% - 300px);
    width: -moz-calc(100% - 300px);
    width: calc(100% - 300px);
    padding: 0 15px;
}

.account-menu-item-bx{
    width: 100%;
    /* max-width: 992px; */
    /* margin: auto; */
    margin-top: 15px;
    padding-bottom: 100px;
}

.amib-992-width{
    width: 100%;
    max-width: 992px;
    margin: auto;
}

.amib-900-width{
    width: 100%;
    max-width: 900px;
    margin: auto;
}

.amib-768-width{
    width: 100%;
    max-width: 768px;
    margin: auto;
}

.amib-576-width{
    width: 100%;
    max-width: 576px;
    margin: auto;
}

.amib-i-header{
    font-size: 18px;
    font-weight: 500;
    color: var(--black);
    margin-bottom: 10px;
}

.amib-i-cancel{
    position: relative;
    display: -webkit-inline-box;
    display: -webkit-inline-flex;
    display: -moz-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
       -moz-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
       -moz-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    padding: 4px 10px 2px;
    font-size: 14px;
    color: #f2f1f3;
    text-transform: capitalize;
    cursor: pointer;
    margin-right: 5px;
    border: 1px solid rgb(255 255 255 / 30%);
    background-color: var(--color1);
    -webkit-border-radius: 50px;
       -moz-border-radius: 50px;
            border-radius: 50px;
    overflow: hidden;
    -webkit-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
    float: right;
}

.amib-i-cancel:hover{
    border-color: var(--color4);
    color: var(--color4);
}

.amib-i-header svg{
    position: relative;
    top: -2px;
    width: 24px;
    height: 24px;
    -o-object-position: center;
       object-position: center;
    -o-object-fit: contain;
       object-fit: contain;
    margin-right: 5px;
}

.amib-i-sublable{
    color: var(--textcolor);
    font-size: 14px;
}

.amib-item{
    padding: 20px;
    -webkit-border-radius: 16px;
       -moz-border-radius: 16px;
            border-radius: 16px;
    background-color: var(--white);
    border: 1px solid var(--black-100);
    box-shadow: 0 0px 20px var(--black-100);
}

.amib-item-bg-none{
    background-color: transparent;
}

.amib-save-btn-bx{
    margin-top: 25px;
}

.amib-save-btn-bx .lsb-loader-btn,
.amib-save-btn-bx .common-submit-btn{
    max-width: 200px;
    display: block;
}


.amib-subinner-bx{
    background-color: #f7f7f7;
    padding-top: 10px;
    border-radius: 10px;
}

.amib-subinner-heading{
    font-size: 16px;
    font-weight: 600;
    padding-bottom: 5px;
    border-bottom: 1px solid lightgray;
    margin-bottom: 15px;
    padding-top: 5px;
}

.reset-btn{
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 45px;
    height: 45px;
    background-color: var(--black-100);
    border-radius: 50px;
    padding: 10px;
    border: 1px solid var(--black-300);
    transition: all 0.3s ease-in-out;
}

.reset-btn svg{
    width: 100%;
    height: 100%;
}

.reset-btn:hover{
    color: white;
    background-color: black;
    border-color: var(--black);
}

/* --- Profile Pic Css ---*/
.profile-pic-bx{
    position: relative;
    width: fit-content;
    margin: 0 auto 15px;
}

.profile-pic-lable {
    position: relative;
    display: block;
    margin: 0;
}

.profile-pic-lable::after{
    content: "";
    position: absolute;
    border-radius: 50%;
    display: block;
    inset: -1px;
    transition: opacity 150ms ease-in-out 0s;
    opacity: 0;
    background-color: var(--cp);
}

.profile-pic-lable .profile-upload{
    position: absolute;
    border-radius: 50%;
    display: block;
    inset: 1px;
    background-color: var(--cp-100);
    z-index: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 20px;
    font-weight: 500;
    opacity: 0;
    transition: opacity 150ms ease-in-out 0s;
    cursor: pointer;
}

.profile-pic-lable:hover .profile-upload,
.profile-pic-lable:hover::after{
    opacity: 1;
}

.profile-pic-img{
    position: relative;
    z-index: 1;
    width: 150px;
    height: 150px;
    border-radius: 50%;
    object-fit: cover;
    object-position: center;
    margin: 10px auto;
    display: block;
    transition: 0.3s ease-in-out;
    cursor: pointer;
    border: 1px solid transparent;
}

.profile-pic-img::after{
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
}

.profile-pic-btn{
    position: absolute;
    z-index: 1;
    bottom: 10px;
    border: none;
    width: 35px;
    height: 35px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    font-size: 18px;
    background: #fbfbfb;
    box-shadow: 0 0 10px #00000029;
    margin: 0;
    cursor: pointer;
    transition: 0.3s ease-in-out;
}

.profile-pic-btn.edit-btn .fa{
    position: relative;
    left: 2px;
    top: 1px;
}

.profile-pic-btn.edit-btn{
    right: 0;
    color: #8036e0;
}

.profile-pic-btn.delete-btn{
    left: 0;
    color: #e30000;
}

.profile-pic-btn.edit-btn:hover{
    color: white;
    background-color: #8036e0;
}

.profile-pic-btn.delete-btn:hover{
    color: white;
    background-color: #e30000;
}


/* --- amib inner tabbx start --- */
.amib-i-tab{
    display: none;
}

.amib-i-tab.active{
    display: block;
}

.amib-inner-tabbx{
    position: relative;
    white-space: nowrap;
    overflow: hidden;
    overflow-x: auto;
    margin: 15px 0;
}

.amib-inner-tabbx .ait-link{
    position: relative;
    display: -webkit-inline-box;
    display: -webkit-inline-flex;
    display: -moz-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
       -moz-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
       -moz-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    padding: 8px 12px 7px;
    font-size: 14px;
    color: #f2f1f3;
    text-transform: capitalize;
    cursor: pointer;
    margin-right: 5px;
    border: 1px solid rgb(255 255 255 / 30%);
    background-color: var(--color1);
    -webkit-border-radius: 50px;
       -moz-border-radius: 50px;
            border-radius: 50px;
    overflow: hidden;
    -webkit-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}

.amib-inner-tabbx .ait-link:hover{
    border-color: white;
}

.amib-inner-tabbx .ait-link.active{
    background-image: -webkit-linear-gradient(135deg, var(--color7), var(--color6));
    background-image: -moz-linear-gradient(135deg, var(--color7), var(--color6));
    background-image: -o-linear-gradient(135deg, var(--color7), var(--color6));
    background-image: linear-gradient(-45deg, var(--color7), var(--color6));
    border-color: transparent;
    color: white;
}

.amib-inner-tabbx .ait-link:last-child{
    margin-right: 0px;
}

.amib-inner-tabbx .ait-link svg{
    position: relative;
    top: -1px;
    width: 18px;
    height: 18px;
    -o-object-position: center;
       object-position: center;
    -o-object-fit: contain;
       object-fit: contain;
    margin-right: 5px;
}
/* --- amib inner tabbx end --- */


/* --- Wallet page css start --- */
.wallet-amount-bx{
    position: relative;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
       -moz-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    -webkit-box-align: center;
    -webkit-align-items: center;
       -moz-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    padding: 15px 20px;
    -webkit-border-radius: 16px;
       -moz-border-radius: 16px;
            border-radius: 16px;
    background-image: -webkit-linear-gradient(45deg, rgb(255 225 208), rgb(255 205 211));
    background-image: -moz-linear-gradient(45deg, rgb(255 225 208), rgb(255 205 211));
    background-image: -o-linear-gradient(45deg, rgb(255 225 208), rgb(255 205 211));
    background-image: linear-gradient(45deg, rgb(255 225 208), rgb(255 205 211));
}

.wallet-amount-bx .walletgrapics{
    position: absolute;
    right: 0;
    bottom: -webkit-calc(100% - 60px);
    bottom: -moz-calc(100% - 60px);
    bottom: calc(100% - 60px);
    width: 200px;
    z-index: 0;
}

.wallet-amount-bx > *{
    position: relative;
    z-index: 1;
}

.wallet-amount-bx .wab-data-bx .wdb-money{
    color: var(--color6);
    font-weight: 700;
    font-size: 36px;
}

.wallet-amount-bx .wab-data-bx .wdb-lable{
    color: var(--color6);
    font-weight: 400;
    margin-top: -5px;
}

.wallet-amount-bx .wab-data-bx .wdb-note{
    color: var(--color1);
    font-weight: 400;
}

.wallet-amount-bx .wab-data-bx .wdb-link{
    display: inline-block;
    color: var(--color1);
    font-weight: 500;
    background-color: var(--color6-100);
    padding: 4px 15px 5px;
    -webkit-border-radius: 50px;
       -moz-border-radius: 50px;
            border-radius: 50px;
    margin-top: 10px;
    font-size: 14px;
    -webkit-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
    text-decoration: underline;
}

.wallet-amount-bx .wab-data-bx .wdb-link:hover{
    color: var(--color6);
    background-color: var(--color6-100);
    text-decoration: underline;
}

.wallet-amount-bx .wab-data-bx .wdb-link svg{
    width: 20px;
    height: 20px;
    margin-right: 8px;
}


.wallet-amount-btns{
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
       -moz-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
       -moz-box-align: center;
        -ms-flex-align: center;
            align-items: center;
}

.wallet-amount-bx .add-money-btn{
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
       -moz-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
       -moz-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    padding: 8px 15px;
    -webkit-border-radius: 50px;
       -moz-border-radius: 50px;
            border-radius: 50px;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    font-weight: 500;
    font-size: 14px;
    position: relative;
    outline: none;
    cursor: pointer;
    color: white;
    border: none;
    background-image: -webkit-linear-gradient(135deg, var(--color7), var(--color6));
    background-image: -moz-linear-gradient(135deg, var(--color7), var(--color6));
    background-image: -o-linear-gradient(135deg, var(--color7), var(--color6));
    background-image: linear-gradient(-45deg, var(--color7), var(--color6));
    -webkit-background-size: 200% 200%;
       -moz-background-size: 200% 200%;
         -o-background-size: 200% 200%;
            background-size: 200% 200%;
    background-position: center left;
    -webkit-transition: background-position 0.3s ease-in-out, -webkit-transform 0.3s ease;
    transition: background-position 0.3s ease-in-out, -webkit-transform 0.3s ease;
    -o-transition: background-position 0.3s ease-in-out, -o-transform 0.3s ease;
    -moz-transition: background-position 0.3s ease-in-out, transform 0.3s ease, -moz-transform 0.3s ease;
    transition: background-position 0.3s ease-in-out, transform 0.3s ease;
    transition: background-position 0.3s ease-in-out, transform 0.3s ease, -webkit-transform 0.3s ease, -moz-transform 0.3s ease, -o-transform 0.3s ease;
    letter-spacing: 0.5px;
    margin-top: 40px;
    margin: 0 5px;
    white-space: nowrap;
}

.wallet-amount-bx .add-money-btn.withdrawal-btn{
    background-image: -webkit-linear-gradient(135deg, var(--color3), var(--color3-700));
    background-image: -moz-linear-gradient(135deg, var(--color3), var(--color3-700));
    background-image: -o-linear-gradient(135deg, var(--color3), var(--color3-700));
    background-image: linear-gradient(-45deg, var(--color3), var(--color3-700));
}

.wallet-amount-bx .add-money-btn:last-child{
    margin-right: 0;
}
.wallet-amount-bx .add-money-btn:first-child{
    margin-left: 0;
}

.wallet-amount-bx .add-money-btn:hover{
    background-position: center right;
}

.wallet-amount-bx .add-money-btn:active{
    -webkit-transform: scale(0.95);
       -moz-transform: scale(0.95);
        -ms-transform: scale(0.95);
         -o-transform: scale(0.95);
            transform: scale(0.95);
}

.wallet-amount-bx .add-money-btn svg{
    width: 26px;
    height: 26px;
    -o-object-fit: contain;
       object-fit: contain;
    -o-object-position: center;
       object-position: center;
    margin-right: 5px;
}

.amib-loadmoney-bx{
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
       -moz-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
}

.amib-loadmoney-bx > *{
    -webkit-box-flex: 0;
    -webkit-flex: 0 1 280px;
       -moz-box-flex: 0;
        -ms-flex: 0 1 280px;
            flex: 0 1 280px;
}

.amib-loadmoney-bx > *:last-child{
    -webkit-box-flex: 0;
    -webkit-flex: 0 1 -webkit-calc(100% - 280px);
       -moz-box-flex: 0;
        -ms-flex: 0 1 calc(100% - 280px);
            flex: 0 1 calc(100% - 280px);
}

.loadmoney-data-bx{
    padding: 15px;
    -webkit-border-radius: 15px;
       -moz-border-radius: 15px;
            border-radius: 15px;
    background-color: var(--color1);
    margin-top: 10px;
    overflow: hidden;
    position: relative;
}

.loadmoney-data-bx .ldb-data-copy{
    width: 45px;
    height: 45px;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
       -moz-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
       -moz-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    position: absolute;
    right: 0;
    top: 0;
    padding: 0 0 10px 10px;
    background: var(--color2-500);
    -webkit-border-radius: 0 0 0 50px;
       -moz-border-radius: 0 0 0 50px;
            border-radius: 0 0 0 50px;
    cursor: pointer;
    -webkit-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}

.loadmoney-data-bx .ldb-data-copy:hover{
    background: var(--color3);
}

.loadmoney-data-bx .ldb-data-copy svg{
    width: 22px;
    height: 22px;
}

.loadmoney-data-bx img{
    display: block;
    max-width: 280px;
    width: 100%;
    margin: auto;
}

.loadmoney-data-bx .ldb-data-item{
    font-size: 12px;
    color: var(--color6);
    padding: 5px 0;
}

.loadmoney-data-bx .ldb-data-item span{
    font-size: 14px;
    color: white;
    display: block;
    margin-top: -2px;
}

.addmoneyform{
    position: -webkit-sticky;
    position: sticky;
    top: 80px;
}

/* --- Wallet page css end --- */



/* --- Appointment page links start --- */
.appointment-item{
    position: relative;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    cursor: pointer;
    width: 100%;
    padding: 10px 10px;
    border-radius: 10px;
    margin: 10px 0;
    transition: all 0.3s ease-in-out;
    gap: 15px;
}

.appointment-item::after{
    content: " ";
    position: absolute;
    left: 0;
    bottom: -5px;
    width: 100%;
    height: 1px;
    background-color: var(--black-100);
}

.appointment-item:nth-child(odd){
    background-color: #ececec;
}

.appointment-item:hover{
    background-color: var(--cp-100);
}

.appointment-item:first-child{
    margin-top: 0;
}

.appointment-item:last-child{
    margin-bottom: 0;
}

.appointment-item:last-child::after{
    content: none;
}

.appointment-item .appointment-img-name{
    display: flex;
    align-items: center;
    gap: 10px;
}

.appointment-item .appointment-img{
    width: 45px;
    height: 45px;
    flex: 0 0 45px;
    border-radius: 50%;
    border: 1px solid lightgray;
}

.appointment-item .appointment-name-lable{
    font-size: 16px;
    font-weight: 800;
    color: var(--black);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.appointment-item .appointment-datetime{
    border-left: 1px solid var(--black-100);
    padding-left: 15px;
}

.appointment-item .appointment-datetime .appointment-date{
    color: var(--black);
    font-size: 14px;
    font-weight: 800;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.appointment-item .appointment-datetime .appointment-time{
    color: var(--textcolor);
    font-size: 14px;
    font-weight: 500;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.appointment-item  .appointment-btns{
    display: flex;
    gap: 5px;
    margin-left: auto;
}

@media screen and (max-width:650px) {
    .appointment-item  .appointment-btns{
        flex: 0 0 100%;
        justify-content: flex-end;
    }
}

@media screen and (max-width:450px) {
    .appointment-item{
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start
    }

    .appointment-item .appointment-datetime{
        padding-left: 0;
        border: none;
    }

    .appointment-item .appointment-btns{
        justify-content: flex-start;
        margin-left: 0;
    }
}

/* --- Appointment page links end --- */


/* --- Notifications page css strat --- */
.nlb-outer{
    margin-bottom: 15px;
}

.nlb-outer:last-child{
    margin-bottom: 0;
}

.nlb-outer-label{
    font-size: 14px;
    font-weight: 600;
    color: var(--black);
    margin-bottom: -0px;
}

.nlb-item{
    position: relative;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    padding: 15px 0;
    border-bottom: 1px solid var(--black-100);
    cursor: pointer;
}

.nlb-item.nlb-new::after{
    content: " ";
    position: absolute;
    left: 30px;
    top: 17px;
    width: 8px;
    height: 8px;
    -webkit-border-radius: 50%;
       -moz-border-radius: 50%;
            border-radius: 50%;
    background-color: var(--cp);
}

.nlb-item:last-child{
    border-bottom: none;
}

.nlb-item .nlb-icon{
    width: 40px;
    height: 40px;
    padding: 10px;
    -webkit-border-radius: 50%;
       -moz-border-radius: 50%;
            border-radius: 50%;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    background-color: var(--cp-100);
    margin-right: 10px;
    -webkit-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}

.nlb-item:hover .nlb-icon{
    background-color: var(--cp-100);
}

.nlb-item .nlb-data .nlb-lable{
    color: var(--cp);
    font-size: 16px;
    font-weight: 500;
}

.nlb-item .nlb-data .nlb-text{
    color: var(--textcolor);
    font-size: 14px;
    font-weight: 500;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
}
/* --- Notifications page css strat --- */


@media screen and (max-width:992px) {
    .account-main-section{
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -webkit-flex-direction: column;
           -moz-box-orient: vertical;
           -moz-box-direction: normal;
            -ms-flex-direction: column;
                flex-direction: column;
    }

    .account-left{
        padding: 0;
        -webkit-box-flex: initial;
        -webkit-flex: initial;
           -moz-box-flex: initial;
            -ms-flex: initial;
                flex: initial;
        width: 100%;
        max-width: 100%;
        height: auto;
        z-index: 11;
        border-bottom: 1px solid var(--black-100);
    }

    .account-left .account-left-pic{
        display: none;
    }

    .account-menu-bx{
        padding: 10px 5px 0 5px;
        margin-top: 0;
        display: block;
        white-space: nowrap;
        overflow-x: scroll;
        -webkit-transition: overflow-x 0.3s ease-in-out;
        -o-transition: overflow-x 0.3s ease-in-out;
        -moz-transition: overflow-x 0.3s ease-in-out;
        transition: overflow-x 0.3s ease-in-out;
    }

    .account-menu-bx .amb-link{
        white-space: nowrap;
        margin: 0;
        -webkit-border-radius: 8px 8px 0 0;
           -moz-border-radius: 8px 8px 0 0;
                border-radius: 8px 8px 0 0;
        margin: 0 3px;
        display: inline-block;
    }

    .account-menu-bx .amb-link::after{
        width: -webkit-calc(100% - 10px);
        width: -moz-calc(100% - 10px);
        width: calc(100% - 10px);
        height: 4px;
        right: inherit;
        top: inherit;
        left: 50%;
        bottom: 0;
        -webkit-transform: translateX(-50%);
           -moz-transform: translateX(-50%);
            -ms-transform: translateX(-50%);
             -o-transform: translateX(-50%);
                transform: translateX(-50%);
        -webkit-border-radius: 50px 50px 0 0;
           -moz-border-radius: 50px 50px 0 0;
                border-radius: 50px 50px 0 0;
    }

    .account-right{
        width: 100%;
        -webkit-box-flex: 1;
        -webkit-flex: auto;
           -moz-box-flex: 1;
            -ms-flex: auto;
                flex: auto;
        padding: 0 10px;
    }

}

@media screen and (max-width:768px) {
    .amib-loadmoney-bx{
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -webkit-flex-direction: column;
           -moz-box-orient: vertical;
           -moz-box-direction: normal;
            -ms-flex-direction: column;
                flex-direction: column;
    }
    
    .amib-loadmoney-bx > *{
        width: 100%;
        -webkit-box-flex: 0;
        -webkit-flex: 0 1 100%;
           -moz-box-flex: 0;
            -ms-flex: 0 1 100%;
                flex: 0 1 100%;
    }
}

@media screen and (max-width:576px) {
    .account-left{
        top: 66px;
    }
    
    /* .amib-item{
        padding: 0px;
        -webkit-border-radius: 0px;
           -moz-border-radius: 0px;
                border-radius: 0px;
        background-color: transparent;
    } */

    .wallet-amount-bx{
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -webkit-flex-direction: column;
           -moz-box-orient: vertical;
           -moz-box-direction: normal;
            -ms-flex-direction: column;
                flex-direction: column;
        -webkit-box-pack: center;
        -webkit-justify-content: center;
           -moz-box-pack: center;
            -ms-flex-pack: center;
                justify-content: center;
        -webkit-box-align: start;
        -webkit-align-items: start;
           -moz-box-align: start;
            -ms-flex-align: start;
                align-items: start;
        padding: 15px 10px;
    }

    .wallet-amount-bx .wab-data-bx{
        margin-bottom: 20px;
    }

    .wallet-amount-btns{
        width: 100%;
        -webkit-box-pack: justify;
        -webkit-justify-content: space-between;
           -moz-box-pack: justify;
            -ms-flex-pack: justify;
                justify-content: space-between;
    }

    .wallet-amount-bx .add-money-btn{
        /* margin-bottom: 20px; */
        margin-top: 0;
    }

    .wallet-amount-bx .walletgrapics{
        right: -14px;
        bottom: 14px;
        width: 220px;
    }

    .loadmoney-data-bx {
        background-color: var(--color5);
    }
    
}



.orderdetail-data{
    color: var(--textcolor);
    font-size: 13px;
    font-weight: 500;
    margin-top: 10px;
}

.orderdetail-data span{
    color: var(--black);
    font-size: 14px;
    font-weight: 800;
}

.orderdetail-data span{
    font-size: 16px;
}

.sb-static{
    position: static;
    border: none;
    box-shadow: none;
    border-radius: 0;
    transform: none;
    padding: 0;
}

.sb-static .summary-heading{
    border-bottom: 1px solid var(--black-100);
}