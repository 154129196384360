.profiledetailbx{
    display: flex;
}


/* --- Profile Left Section Start --- */
.pdb-left{
    width: 100%;
    flex: 0 0 420px;
}

.pdb-left .pdb-img {
    width: 100%;
    border-radius: 5px;
    position: sticky;
    top: 130px;
}
/* --- Profile Left Section End --- */


/* --- Profile Right Section Start --- */
.pdb-right{
    height: 100%;
    padding-left: 30px;
    display: flex;
    flex-direction: column;
    gap: 12px;
}

.pdb-name {
    font-size: 36px;
    color: var(--black);
    font-weight: 600;
}

.pdb-speciality {
    background: var(--cp-100);
    width: -moz-fit-content;
    width: fit-content;
    padding: 4px 12px;
    border-radius: 50px;
    font-size: 14px;
    font-weight: 500;
    color: var(--cp);
    margin: 5px 0 5px 0;
}

.pdb-location {
    color: var(--textcolor);
    font-size: 14px;
    font-weight: 500;
    font-size: 14px;
    line-height: 1.8;
    width: 100%;
}

.pdb-location svg {
    width: 20px;
    height: 20px;
    margin-right: 2px;
    position: relative;
    top: -1px;
}

.pdb-start-rating{
    display: flex;
    gap: 10px;
    margin-top: -5px;
}   

.pdb-start-rating .pdb-star{
    display: flex;
    align-items: center;
}

.pdb-start-rating .pdb-star svg{
    display: flex;
    width: 20px;
    height: 20px;
    color: #c9c9c9;
    position: relative;
    top: -1px;
}   

.pdb-start-rating .pdb-star.star1 svg:nth-child(1){
    color: var(--gold);
}  

.pdb-start-rating .pdb-star.star2 svg:nth-child(2),
.pdb-start-rating .pdb-star.star2 svg:nth-child(1){
    color: var(--gold);
}   

.pdb-start-rating .pdb-star.star3 svg:nth-child(3),
.pdb-start-rating .pdb-star.star3 svg:nth-child(2),
.pdb-start-rating .pdb-star.star3 svg:nth-child(1){
    color: var(--gold);
}   

.pdb-start-rating .pdb-star.star4 svg:nth-child(4),
.pdb-start-rating .pdb-star.star4 svg:nth-child(3),
.pdb-start-rating .pdb-star.star4 svg:nth-child(2),
.pdb-start-rating .pdb-star.star4 svg:nth-child(1){
    color: var(--gold);
}   

.pdb-start-rating .pdb-star.star5 svg:nth-child(5),
.pdb-start-rating .pdb-star.star5 svg:nth-child(4),
.pdb-start-rating .pdb-star.star5 svg:nth-child(3),
.pdb-start-rating .pdb-star.star5 svg:nth-child(2),
.pdb-start-rating .pdb-star.star5 svg:nth-child(1){
    color: var(--gold);
}   

.pdb-start-rating .pdb-star-num{
    padding: 2px 10px;
    font-weight: 700;
    font-size: 14px;
    color: var(--green);
    border-radius: 5px;
    background-color: var(--green-100);
}

.pdb-about-text{
    font-size: 14px;
    color: var(--textcolor);
    font-weight: 500;
}

.pdb-price div{
    display: inline-block;
    position: relative;
    color: var(--textcolor);
    font-size: 20px;
    font-weight: 500;
}

.pdb-price div span{
    color: var(--black-700);
    font-size: calc(100% - 5px);
}


.pdb-btn{
    width: 100%;
    max-width: 320px;
}

/* --- Profile Right Section End --- */



@media screen and (max-width:992px) {
    .pdb-left{
        flex: 0 0 320px;
    }

    .pdb-name {
        font-size: 32px;
    }
}

@media screen and (max-width:700px) {
    .profiledetailbx{
        flex-direction: column;
    }

    .pdb-left{
        flex: initial;
    }

    .pdb-left img{
        width: 100%;
        max-width: 420px;
    }

    .pdb-right{
        margin-top: 15px;
        padding-left: 0;
    }

    .pdb-name {
        font-size: 28px;
    }
}

@media screen and (max-width:576px) {
    .pdb-name {
        font-size: 24px;
    }
    .pdb-btn{
        max-width: 100%;
    }
}





/* --- custom-table --- */
.custom-table{
    width: 100%;
    border-collapse: separate;
}

.custom-table thead th{
    padding: 10px;
    font-size: 16px;
    font-weight: 600;
    color: var(--black);
    border-bottom: 1px solid var(--black-100);
}

.custom-table tbody td{
    padding: 5px 10px;
    font-size: 14px;
    font-weight: 500;
    color: var(--textcolor);
    border-bottom: 1px solid var(--black-100);
}

.custom-table tbody tr:last-child td{
    border-bottom: none;
}
/* --- Custom table --- */


/* --- Checkout page Css --- */
.appointmentbx .pdb-l-bx .pdb-name{
    font-size: 18px;
}

.appointmentbx .pdb-l-bx .pdb-img{
    width: 100px;
    height: 100px;
    margin: 0 !important;
}

.appointmentbx .pdb-l-bx .pdb-location{
    max-width: 100%;
}

.appointmentbx .pdb-l-bx .pdb-data-bx{
    padding-left: 10px;
}

@media screen and (max-width:450px) {
    .pdb-l-bx{
        flex-direction: row;
    }
}


/* --- Slot Time Box Css Start --- */
.slot-box {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    gap:10px;
}

.slot-box .slot-time {
    display: inline-block;
    border: 1px solid var(--black-100);
    padding: 5px 6px;
    border-radius: 5px;
    font-size: 15px;
    font-weight: 600;
    width: 90px;
    text-align: center;
    cursor: pointer;
    transition: 0.3s ease-in-out;
    color: black;
    box-shadow: 0 0 7px rgb(0 0 0 / 10%);
}

.slot-box .slot-time:not(.slot-disable, .selected-slot):hover {
    color: var(--black);
    border-color: var(--cp);
    background-color: var(--cp-100);
}

.slot-box .slot-time.selected-slot {
    color: white;
    border-color: var(--green);
    background-color: var(--green);
}

.slot-box .slot-time.slot-disable {
    /* pointer-events: none; */
    cursor: not-allowed;
    background: var(--black-100);
    opacity: 0.8;
    box-shadow: none;
}

@media screen and (max-width:576px) {
    .slot-box .slot-time{
        width: calc(33.33% - 7px);
    }
}

/* --- End Slot Time Box Css --- */




.cbi-proceedpay{
    font-size: 16px;
    height: 45px;
    font-weight: 500;
}