.custom-header{
    position: sticky;
    top: 0px;
    z-index: 101;
    padding: 0 15px;
    background-color: var(--white);
    transition: all 0.3s ease-in-out;
    /* height: 122px; */
}

.custom-header::after{
    content: "";
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 1px;
    background-color:var(--black-100);
}

.custom-header.ch-active,
.custom-header.active{
    box-shadow: 0 0 40px -20px var(--black-300);
}


/* --- Custom Header Top Strip --- */
.ch-top-bx{
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding-top: 10px;
    gap: 5px;
}

.ch-top-bx .ctb-contact{
    display: flex;
    align-items: center;
    gap: 5px;
}

.ch-top-bx .ctb-contact .contact-item{
    position: relative;
    display: flex;
    align-items: center;
    color: var(--textcolor);
    font-weight: 500;
    font-size: 15px;
    padding: 5px 10px;
    border-radius: 50px;
    transition: all 0.3s ease-in-out;
}

.ch-top-bx .ctb-contact .contact-item::after{
    content: "";
    position: absolute;
    left: 35px;
    top: 50%;
    transform: translateY(-50%);
    background-color: var(--black-100);
    width: 1px;
    height: 50%;

}

.ch-top-bx .ctb-contact .contact-item svg{
    width: 20px;
    height: 20px;
    object-position: center;
    object-fit: contain;
    margin-right: 14px;
    transition: all 0.3s ease-in-out;
    color: var(--cp);
}

.ch-top-bx .ctb-contact .contact-item:hover{
    color: var(--black);
    text-decoration: underline;
}
/* --- Custom Header Top Strip --- */


/*  */
.ch-inner{
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    min-height: 66px;
    padding: 0px 0 0;
}

.ch-inner .ch-right,
.ch-inner .ch-left{
    display: flex;
    align-items: center;
    width: 194px;
    flex: 0 0 auto;
}

.ch-inner .ch-left{
    justify-content: flex-start;
}

.ch-inner .ch-right{
    justify-content: flex-end;
}


/* --- Logo Css Start --- */
/* .ch-logo{
    cursor: pointer;
    position: absolute;
    left: 0;
    top: -30px;
} */

.ch-logo img{
    width: 176px;
    /* height: 90px; */
    display: block;
    object-fit: contain;
}
/* --- Logo Css End --- */

/* --- Menu Links Start --- */
.ch-menu-bx{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;
    padding: 0 15px;
    width: 100%;
}

.ch-menu-bx .cmb-item{
    position: relative;
    z-index: 2;
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--black);
    font-size: 16px;
    white-space: nowrap;
    padding: 0px 0px;
    height: 66px;
    font-weight: 400;
    /* opacity: 0.6; */
    overflow: hidden;
    transition:all 0.3s ease-in-out;
}

.ch-menu-bx .cmb-item svg{
    width: 16px;
    height: 16px;
    margin-left: 5px;
}

.ch-menu-bx .cmb-item .cmb-item-line{
    position: absolute;
    left: 0px;
    bottom: 1px;
    width: 100%;
    height: 2px;
    background-image: linear-gradient(-90deg, var(--white), var(--cp), var(--white));
    z-index: 1;
    opacity: 0;
    transition: all 0.3s ease-in-out;
}

.ch-menu-bx .cmb-item:hover,
.ch-menu-bx .cmb-item.active{
    opacity: 1;
}

.ch-menu-bx .cmb-item.active .cmb-item-line,
.ch-menu-bx .cmb-item:hover .cmb-item-line{
    opacity: 1;
}
/* --- Menu Links End --- */


/* --- Signinup Btn Bx Start --- */
.signinup-btn-bx{
    display: flex;
    justify-content: flex-end;
    gap: 8px;
}

.signinup-btn-bx .sip-btn{
    display: flex;
    justify-self: center;
    align-items: center;
    position: relative;
    color: var(--black);
    border: 1px solid transparent;
    padding: 0px 15px;
    height: 40px;
    border-radius: 50px;
    font-size: 16px;
    font-weight: 600;
    transition:all 0.3s ease-in-out;
    overflow: hidden;
    white-space: nowrap;
}

.signinup-btn-bx .sip-btn span{
    position: relative;
    z-index: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    transition: all 0.3s ease-in-out;
}

.signinup-btn-bx .sip-btn.register-sip-btn{
    border-color: var(--black-300);
}

.signinup-btn-bx .account-sip-btn{
    padding: 0px 6px;
}


.signinup-btn-bx .sip-btn.sip-account-btn{
    display: flex;
    justify-content: center;
    align-items: center;
}

.signinup-btn-bx .sip-btn img{
    width: 30px;
    height: 30px;
    position: relative;
    top: 0.5px;
    border-radius: 50px;
    margin-right: 5px;
    object-fit: cover;
    object-position: center;
}

.signinup-btn-bx .sip-btn:hover{
    color: var(--white);
    border-color: var(--cp);
    background-color: var(--cp);
    cursor: pointer;
}

.signinup-btn-bx .sip-btn.register-sip-btn:hover{
    color: var(--black);
    background-color: transparent;
}


.signinup-btn-bx .sip-btn.register-sip-btn::before{
    content: attr(sbdata);
    position: absolute;
    inset: 0px;
    color: var(--white);
    background: var(--cp);
    z-index: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    transform: translateX(-100%);
    transition: all 0.3s ease-in-out;
}

.signinup-btn-bx .sip-btn.register-sip-btn:hover span{
    transform: translateX(calc(100% + 20px));
}

.signinup-btn-bx .sip-btn.register-sip-btn:hover::before{
    transform: translateX(0);
}
/* --- Signinup Btn Bx End --- */


/* --- Aside Menu Icon Start --- */
.menu-icon-bx {
    width: 30px;
    height: 30px;
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 30px;
       -moz-box-flex: 0;
        -ms-flex: 0 0 30px;
            flex: 0 0 30px;
    display: none;
    -webkit-justify-content: space-around;
        -ms-flex-pack: distribute;
            justify-content: space-around;
    -webkit-box-align: center;
    -webkit-align-items: center;
       -moz-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
       -moz-box-orient: vertical;
       -moz-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    padding: 0px;
    cursor: pointer;
    margin-left: 15px;
}

.menu-icon-bx span {
    display: block;
    width: 100%;
    height: 3px;
    -webkit-border-radius: 100px;
       -moz-border-radius: 100px;
            border-radius: 100px;
    opacity: 0.8;
    background-color: var(--black-300);
    -webkit-transition: 0.3s ease-in-out;
    -o-transition: 0.3s ease-in-out;
    -moz-transition: 0.3s ease-in-out;
    transition: 0.3s ease-in-out;
}

.menu-icon-bx:hover span,
.menu-icon-bx.active span{
    opacity: 1;
    background-color: var(--cp);
}

.menu-icon-bx.active span:nth-child(1) {
    width: 50%;
    margin-left: auto;
}

.menu-icon-bx.active span:nth-child(2) {
    width: 90%;
}

.menu-icon-bx.active span:nth-child(3) {
    width: 50%;
    margin-right: auto;
}
/* --- Aside Menu Icon End --- */


/* --- @media Css Start --- */
@media screen and (max-width:768px) {

    .ch-inner .ch-right,
    .ch-inner .ch-left{
        width: auto;
    }
    
}

@media screen and (max-width:768px) {

    .menu-icon-bx{
        display: flex;
    }

    /* --- @Menu Links Start --- */
    .ch-menu-bx{
        display: none;
    }
    /* --- @Menu Links End --- */
    
}

@media screen and (max-width:768px) {

    .ch-logo{
        position: static;
        width: 100%;
    }

    .ch-logo img{
        height: initial;
    }
    
}

/* @media screen and (max-width:576px) {

    .ch-top-bx .ctb-contact .contact-item:nth-child(1){
        display: none;
    }
    
} */

@media screen and (max-width:450px) {

    .signinup-btn-bx .sip-btn:not(.login-sip-btn),
    .ch-top-bx .ctb-contact .contact-item:nth-child(1){
        display: none;
    }
    
}

@media screen and (max-width:360px) {
    .ch-logo img{
        width: 120px;
    }
}
/* --- @media Css End --- */