/* --- Footer Css --- */
footer{
    position: relative;
    z-index: 1;
    background-color: var(--white);
    box-shadow: 0 0 40px -20px var(--black-300);
    border-top: 1px solid var(--black-100);
}

/* footer::before{
    content: "";
    position: absolute;
    bottom: 0;
    height: 100%;
    width: 100%;
    background-image: url("../img/footer-bg.png");
    background-position: center bottom;
    background-repeat: no-repeat;
    background-size: contain;
    opacity: .5;
} */

footer > *{
    position: relative;
    z-index: 1;
}

/* --- Footer logo Css --- */
.footer-logo img{
    width: 170px;
    margin-bottom: 15px;
    cursor: pointer;
}

/* --- Footer Heading Css --- */
.footer-heading{
    color: var(--black);
    font-size: 16px;
    font-weight: 600;
    margin-top: 0px;
    margin-bottom: 8px;
}

.footer-sub-heading{
    color: var(--textcolor);
    font-size: 14px;
    font-weight: 500;
    margin-right: 0;
    margin-top: 5px;
    margin-bottom: 5px;
}

/* --- footer Links Css --- */
.footer-links-bx{
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
}

.footer-links-bx .footer-link{
    position: relative;
    display: flex;
    align-items: center;
    color: var(--textcolor);
    font-size: 14px;
    font-weight: 500;
    margin-right: 0;
    margin-top: 5px;
    margin-bottom: 5px;
    cursor: pointer;
    transition: all 0.4s ease-in-out;
}

.footer-links-bx .footer-link svg{
    width: 16px;
    height: 16px;
    object-fit: contain;
    object-position: center;
    margin-right: 5px;
}

.footer-links-bx .footer-link:hover {
    color: var(--black);
}

.footer-links-bx .footer-link.active {
    color: var(--cp);
}

/* --- flb-flex-row --- */
.flb-flex-row{
    flex-direction: row;
}

.flb-flex-row .footer-link{
    width: 50%;
    flex: 0 0 auto;
}


/* --- Footer content detail --- */
.footer-contact-item {
    display: flex;
    align-items: center;
    padding: 8px 0px;
    transition: all 0.3s ease-in-out;
    cursor: pointer;
}

.footer-contact-item .fci-img {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 20px;
    height: 20px;
    object-position: center;
    object-fit: cover;
    margin-right: 5px;
    color: var(--cp);
    transition: all 0.3s ease-in-out;
}

.footer-contact-item .fci-data {
    font-size: 16px;
    font-weight: 500;
    display: inline-block;
    transition: all 0.3s ease-in-out;
}

.footer-contact-item:hover .fci-data {
    color: var(--black);
    text-decoration: underline;
}




/* --- Footer copyright & mede-with section Css --- */
.footer-bottom {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px 0;
    margin-top: 15px;
    border-top: 1px solid var(--black-100);
}

.copyright {
    color: var(--black);
    font-size: 14px;
    font-weight: 500;
}

.mede-with {
    color: var(--black);
    font-size: 14px;
    margin: 0;
    font-weight: 500;
}

.mede-with a {
    color: var(--textcolor);
    font-weight: 500;
}

.mede-with:hover a,
.mede-with a:hover {
    color: var(--cp);
}

.icons-box-made {
    width: 16px;
    height: 16px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    position: relative;
    top: 4px;
    margin: 0 2px;
}

.icons-box-made svg{
    position: absolute;
}

.mede-with svg:nth-child(1) {
    color: var(--cp);
    font-size: 12px;
    transition: all 0.3s;
    opacity: 1;
}

.mede-with svg:nth-child(2) {
    color: var(--cp);
    font-size: 12px;
    transition: all 0.3s;
    opacity: 0;
    transform: scale(0);
}

.mede-with:hover svg:nth-child(1) {
    opacity: 0;
}

.mede-with:hover svg:nth-child(2) {
    opacity: 1;
    color: var(--cp);
    transform: scale(1);
}


@media screen and (max-width:576px) {
    .footer-bottom {
        flex-direction: column;
        justify-content: center;
    }

    .copyright {
        margin-bottom: 10px;
    }
}