.search-main-section{
    display: flex;
    background: var(--cf);
}


/* --- Search Page Left Css Start --- */
.spage-left{
    position: fixed;
    top: 66px;
    z-index: 2;
    right: 0;
    height: calc(100vh - 66px);
    overflow: hidden;
    overflow-y: auto;
    flex: 0 1 280px;
    width: 280px;
    background-color: var(--cf);
    opacity: 0;
    visibility: hidden;
    box-shadow: 0 0 40px -20px var(--black-300);
    transform: translateX(100%);
    padding-bottom: 100px;
    transition:  transform 0.3s ease-in-out, opacity 0.3s ease-in-out, visibility 0.3s ease-in-out;
}

.spage-left.active{
    opacity: 1;
    visibility: visible;
    transform: translateX(0);
}

.spage-left::-webkit-scrollbar{
    display: none;
}

.sp-left-header {
    padding: 16px 15px;
    position: sticky;
    height: 59px;
    top: 0px;
    z-index: 1;
    background-color: var(--cf);
    border-bottom: 1px solid var(--black-100);
}

.sp-left-header .slp-lable {
    margin: 0;
    font-weight: 600;
    font-size: 18px;
    color: var(--black);
    letter-spacing: 0.5px;
}

.sp-left-header .close-icon{
    display:none;
}

.all-reset-btn {
    position: absolute;
    right: 13px;
    top: 15px;
    font-size: 13px;
    font-weight: 500;
    padding: 4px 10px 3px;
    display: block;
    color: var(--black);
    border: 1px solid var(--black-100);
    border-radius: 25px;
    letter-spacing: 0.5px;
    user-select: none;
    transition: 0.3s ease-in-out;
}

.all-reset-btn.active {
    cursor: pointer;
    opacity: 1;
    visibility: visible;
}

.all-reset-btn.active:hover {
    color: var(--black);
    background-color:var(--black-100);
    border-color: var(--black);
}

.acc-item {
    position: relative;
    padding: 0 15px;
    border-bottom: 1px solid var(--black-100);
}

.acc-item:last-child {
    border-bottom: none;
}

.acc-item .acc-heding {
    color: var(--black);
    padding: 12px 0px;
    background: transparent;
    font-weight: 600;
    text-transform: capitalize;
    font-size: 16px;
    position: relative;
    cursor: pointer;
    transition: all 0.3s ease;
}

.acc-item .acc-heding.collapsed {
    opacity: 0.7;
}

.acc-item .acc-heding.collapsed:hover {
    opacity: 1;
}

.acc-item .acc-heding span.down-icon {
    position: absolute;
    right: 0px;
    top: 50%;
    transform: translateY(-50%);
    display: flex;
    justify-content: center;
    align-items: center;
    width: 25px;
    height: 25px;
    font-size: 20px;
    border-radius: 50%;
    transition: 0.3s ease;

}

.acc-item .acc-heding.collapsed span.down-icon {
    transform: translateY(-50%) rotate(180deg);
}

.acc-item .acc-detail {
    position: relative;
}

.checkbox-group {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    gap: 0em;
    margin-bottom: 10px;
}

.checkbox-group.cg-flex-row {
    flex-direction: row;
}

.checkbox-group input {
    display: none;
}

.checkbox-group label {
    cursor: pointer;
    position: relative;
    color:  var(--textcolor);
    font-size: 14px;
    font-weight: 500;
    text-transform: capitalize;
    margin: 4px 0;
    padding: 5px 0px 5px 0px;
    width: fit-content;
    display: flex;
    /* align-items: center; */
    border-radius: 50px;
    background-color: transparent;
    transition: 0.3s ease-in-out;
}


.checkbox-group label .fa-star{
    color: var(--color9);
    margin: 0 5px;
}

.checkbox-group label .fa-user{
    margin: 0 5px 0 5px;
    position: relative;
    top: -1px;
}

/* .checkbox-group.start-boxs label {
    padding: 4px 8px;
    margin: 2px 0;
}

.checkbox-group label .svg-inline--fa.fa-star {
    color: var(--color9);
    font-size: 16px;
}

.checkbox-group label .svg-inline--fa.fa-star.star-active {
    color: var(--color9);
} */

/* --- --- */
.checkbox-group.cg-align-center label{
    align-items: center;
}

.checkbox-group.cg-align-center label span.check-icon{
    top: 0.5px;
}

/* --- --- */
.checkbox-group.cg-flex-row label {
    margin-right: 8px;
    border: 1px solid var(--textcolor);
    padding: 5px 10px;
    font-weight: 500;
}

.checkbox-group.cg-flex-row label:hover {
    background-color: var(--black-100);
}

.checkbox-group.cg-flex-row input:checked + label {
    background-color: var(--blue);
    border-color: var(--blue);
    color: var(--white);
}


.checkbox-group label img {
    width: 20px;
    height: 20px;
    border-radius: 3px;
    -o-object-fit: contain;
    object-fit: contain;
    -o-object-position: center;
    object-position: center;
    margin-right: 5px;
}

.checkbox-group.round-img label img {
    -o-object-fit: cover;
    object-fit: cover;
    -o-object-position: center;
    object-position: center;
    border-radius: 50%;
}

.checkbox-group label span.check-icon {
    position: relative;
    top: -0.5px;
    width: 20px;
    height: 20px;
    flex: 0 0 20px;
    border-radius: 50%;
    margin-right: 8px;
    background-color: transparent;
    background-position: center 1px;
    box-shadow: 0 0 0px 4px transparent inset;
    border: 2px solid var(--black-300);
    transition: 0.3s ease-in-out;
}

.checkbox-group.check-icon-hide label span.check-icon {
    display: none;
}

.checkbox-group.check-square label span.check-icon {
    border-radius: 3px;
}

.checkbox-group label span.num-tag {
    position: absolute;
    right: 6px;
    top: 6px;
    font-size: 14px;
    font-weight: 500;
    /* width: 30px; */
    text-align: right;
    display: block;
    color: var(--textcolor);
    /* display: none; */
}

.checkbox-group label span.num-tag::before {
    content: "(";
}

.checkbox-group label span.num-tag::after {
    content: ")";
}

.checkbox-group.layover-cb label {
    padding: 5px 10px 5px 10px;
    border-radius: 20px;
}

.checkbox-group.full-width-cb label {
    width: 100%;
    padding-right: 35px;
}

.checkbox-group label:hover {
    color: var(--black);
}

.checkbox-group input:checked+label {
    color: var(--black);
}

.checkbox-group input:checked+label span.check-icon {
    background-color: var(--white);
    box-shadow: 0 0 0px 4px var(--blue) inset;
}

.checkbox-group.check-square input:checked+label span.check-icon {
    border-color: var(--blue);
    background-image: url(../img/icons/check.svg);
    background-color: var(--blue);
    background-repeat: no-repeat;
    background-position: center 1px;
    background-size: 90%;
    box-shadow: none;
}


/* --- Serach Page Right Css Start --- */
.spage-right{
    flex: 1 0 calc(100% - 280px);
    width: calc(100% - 280px);
    padding: 0 0 15px;
    transition: all 0.3s ease;
}

.spage-right.active{
    padding-right: 280px;
}

/* --- Search Page Top Bx Css start --- */
.spage-r-top{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 10px;
    position: sticky;
    top: 66px;
    background: var(--cf);
    border-bottom: 1px solid var(--black-100);
    z-index: 2;
}


.spage-r-lable{
    font-size: 16px;
    line-height: normal;
    font-weight: 600;
}

.showing-result{
    color:  var(--textcolor);
    font-size: 12px;
    display: block;
    font-weight: 500;
    margin-top: 4px;
}

.spage-r-btn{
    display: flex;
    justify-content: center;
    align-items: center;
}

.spage-r-btn .sortfilterbtn{
    border: 1px solid var(--black-500);
    padding: 7px 14px;
    border-radius: 50px;
    color: var(--black);
    font-size: 13px;
    margin-right: 10px;
    font-weight: 600;
    transition: 0.3s ease-in-out;
    cursor: pointer;
}

.spage-r-btn .sortfilterbtn:last-child{
    margin-right: 0;
}

.spage-r-btn .sortfilterbtn span{
    display: inline-block;
    font-size: 14px;
    font-weight: 500;
    color: var(--textcolor);
    transition: 0.3s ease-in-out;
}

.spage-r-btn .sortfilterbtn:hover span{
    color: var(--white);
}

.spage-r-btn .sortfilterbtn span::before {
    content: ": (";
}

.spage-r-btn .sortfilterbtn span::after {
    content: ")";
}

.spage-r-btn .sortfilterbtn.active,
.spage-r-btn .sortfilterbtn:hover{
    background-color: var(--blue);
    border-color: var(--blue);
    color: var(--white);
}


.spage-r-btn .sortfilterbtn svg{
    width: 20px;
    height: 20px;
    margin-right: 5px;
    object-fit: contain;
    object-position: center;
    position: relative;
    top: -1px;
}

.spage-r-btn .sortfilterbtn:nth-child(1) svg{
    padding: 1px;
}
/* --- Search Page Left Css End --- */


/* --- Search Content Bx Start --- */
.search-result-bx {
    padding: 0 20px;
    padding-bottom: 100px;
}

.search-card-item {
    position: relative;
    display: block;
    margin: 20px 0 0 0;
    border-radius: 16px;
    background-color: var(--white);
    box-shadow: 0 0 0 1px var(--black-100);
    overflow: hidden;
    z-index: 1;
    transition: 0.3s ease-in-out;
}

.search-card-item:hover {
    box-shadow: 0 0 0 1px transparent, 0 0 20px -5px var(--black-300);
    z-index: 0;
}

.sci-top-bx {
    padding: 10px;
    display: block;
}

.sci-top-bx .sci-img {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    object-fit: cover;
    object-position: center;
    margin: 15px auto 15px;
    display: block;
    transition: 0.3s ease-in-out;
    background-color: lightgray;
}

.sci-top-bx .sci-name {
    font-size: 16px;
    color: var(--black);
    font-weight: 600;
    margin-bottom: 5px;
    text-align: center;
}

.sci-top-bx .sci-speciality {
    background: var(--cp-100);
    width: fit-content;
    margin: 8px auto 0;
    padding: 4px 12px;
    border-radius: 50px;
    font-size: 12px;
    font-weight: 500;
    color: var(--cp);
}

.sci-top-bx .sci-location {
    color: var(--textcolor);
    font-size: 14px;
    text-align: center;
    margin-top: 10px;
    font-weight: 500;
    font-size: 14px;
    line-height: 1.8;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
}

.sci-top-bx .sci-location svg {
    width: 20px;
    height: 20px;
    margin-right: 2px;
    position: relative;
    top: -1px;
}

.sci-top-bx .sci-start-rating {
    position: absolute;
    right: 0;
    top: 0;
    width: fit-content;
    color: var(--green);
    background: var(--green-100);
    font-size: 14px;
    padding: 6px 10px;
    border-radius: 0 0 0 12px;
    font-weight: 600;
    display: flex;
    align-items: center;
    overflow: hidden;
}

.sci-top-bx .sci-start-rating::after {
    content: none;
    position: absolute;
    right: -16px;
    top: 2px;
    width: 26px;
    height: 26px;
    background-color: var(--white);
    border-radius: 5px;
    transform: rotate(45deg);
}

.sci-top-bx .sci-start-rating svg {
    width: 18px;
    height: 18px;
    margin-right: 3px;
    /* font-size: 12px; */
    position: relative;
    top: -1px;
    color: var(--gold);
}

.sci-btn-bx {
    display: flex;
    justify-content: center;
    align-items: center;
    border-top: 1px solid var(--black-100);
    width: 100%;
    overflow: hidden;
    position: relative;
}

.sci-btn-bx::after {
    content: "";
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    border-left: 1px solid var(--black-100);
    height: calc(100% - 10px);
}

.sci-btn-bx .sci-btn-link {
    width: 100%;
    height: 40px;
    padding: 5px 5px;
    text-align: center;
    font-size: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 600;
    color: var(--textcolor);
    transition: 0.3s ease-in-out;
}

.sci-btn-bx .sci-btn-link:hover {
    background-color: var(--cp-100);
    color: var(--cp);
}

.sci-btn-bx .sci-btn-link:last-child:hover {
    background-color: var(--cp-100);
    color: var(--cp);
}

.sci-btn-bx .sci-btn-link svg {
    width: 18px;
    height: 18px;
    margin-right: 3px;
    position: relative;
    top: -1px;
}

@media (min-width: 1200px){
    .search-main-section .spage-right .spage-r-list .col-lg-3 {
        width: 20%;
        transition: flex 0.3s ease;
    }

    .search-main-section .spage-right.active .spage-r-list .col-lg-3 {
        width: 25%;
        transition: flex 0.3s ease;
    }
}

@media screen and (max-width: 576px){
    .sci-top-bx{
       display: flex;
       gap: 20px;
       padding-left: 20px;
    }

    .sci-top-bx .sci-name,
    .sci-top-bx .sci-location{
        text-align: left;
    }

    .sci-top-bx .sci-speciality{
        margin:8px 0 0;
    }

    .sci-top-bx .sci-location{
        padding-left: 22px;
        position: relative;
    }

    .sci-top-bx .sci-location svg{
        position: absolute;
        left: 0;
        top: 3px;
    }
}

/* --- Serach Page Right Css End --- */


/* --- Sort Filter Popup Css --- */
.sort-filter-popup{
    max-width: 420px;
    width: 100%;
    position: fixed;
    opacity: 0;
    visibility: hidden;
    z-index: 111;
    transition: transform 0.3s ease-in-out, opacity 0.3s ease-in-out, visibility 0.3s ease-in-out;
}

.sort-filter-popup.active{
    opacity: 1;
    visibility: visible;
}

.sort-filter-inner{
    position: relative;
    padding: 20px 25px 15px;
    background-color: var(--cf);
    border-radius: 16px;
    width: 100%;
}

.sort-popup-lable{
    font-size: 20px;
    line-height: normal;
    font-weight: 500;
    color:  var(--color3);
    margin-bottom: 10px;
}

.sort-filter-popup .checkbox-group label{
    font-size: 16px;
}
/* --- Sort Filter Popup Css End --- */

/* --- sortfilter Footer Btn --- */
.sortfilter-footer{
    display: none;
    align-items: center;
    justify-content: center;
    padding: 15px 5px 15px;
    position: sticky;
    bottom: 0px;
    left: 0;
    width: 100%;
    background: var(--cf);
    border-top: 1px solid var(--black-100);
}

.sortfilter-footer > div.sff-btn{
    display: block;
    width: 100%;
    height: 35px;
    line-height: 35px;
    border-radius: 50px;
    background-color: var(--color2);
    color: var(--white);
    text-align: center;
    font-size: 14px;
    width: 140px;
    margin: 0 10px;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
}

.sortfilter-footer > div.sff-btn:active{
    transform: scale(0.95);
}
/* --- sortfilter Footer Btn End --- */


@media (min-width: 700px){
    .sort-filter-popup{
        padding: 10px;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%) scale(0.9);
    }
    
    .sort-filter-popup.active{
        transform: translate(-50%, -50%) scale(1);
    }
}

@media (max-width: 1200px){

    .search-main-section{
        margin-top: 0;
    }

    .spage-right.active{
        padding-right: 0;
    }

    .spage-left{
        top: 0px;
        height: calc(100vh - 0px);
        z-index: 111;
    }

    .all-reset-btn{
        right: 55px;
    }

    .sp-left-header .close-icon{
        display:flex;
        top: 13px;
    }

    .serach-overlay.filter-active{
        visibility: visible;
        opacity: 1;
    }
}

@media (max-width: 700px){
    .sortfilter-footer{
        display: flex;
    }

    .spage-r-btn{
        position: fixed;
        bottom: 0;
        background: var(--white);
        width: 100%;
        padding: 0px;
        left: 0;
        border-top: 1px solid var(--black-100);
        box-shadow: 0 0 40px -20px var(--black-300);
    }

    .spage-r-btn::after{
        content: "";
        position: absolute;
        width: 1px;
        height: 80%;
        background-color: var(--black-100);
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }

    .spage-r-btn .sortfilterbtn{
        flex: 0 0 50%;
        border: none;
        text-align: center;
        padding: 15px 5px;
        margin: 0;
        border-radius: 0;
    }

    .spage-r-btn .sortfilterbtn:hover span{
        color: var(--textcolor);
    }

    .spage-r-btn .sortfilterbtn.active,
    .spage-r-btn .sortfilterbtn:hover{
        background-color: var(--white);
        color: var(--black);
    }


    .spage-right{
        padding-bottom: 100px;
    }

    .spage-left {
        width: 100%;
        top: inherit;
        bottom: 0;
        right: 0;
        height: auto;
        overflow: hidden;
        border-radius: 16px 16px 0 0;
        transform: translateY(100%);
        padding-bottom: 0;
    }

    .spage-left.active{
        transform: translateY(0%);
    }

    .spage-left .serach-filter-accordion{
        max-height: calc(100vh - 115px - 70px);
        overflow: hidden;
        overflow-y: auto;
    }

    .spage-left .serach-filter-accordion::-webkit-scrollbar{
        display: none;
    }

    .sort-filter-popup{
        left: 0;
        bottom: 0;
        max-width: 100%;
        transform: translateY(100%);
    }

    .sort-filter-popup.active{
        transform: translateY(0%);
    }

    .sort-filter-inner{
        max-height: calc(100vh - 115px - 70px);
        overflow: hidden;
        overflow: auto;
        border-radius: 16px 16px 0 0;
        padding: 0px;
    }

    .sort-filter-inner::-webkit-scrollbar{
        display: none;
    }

    .sort-popup-lable {
        padding: 25px 20px 0;
    }

    .sort-filter-popup .checkbox-group{
        padding: 0 20px;
    }
}

@media (max-width: 576px){
    .spage-r-btn .sortfilterbtn span{
        display: none;
    }
}