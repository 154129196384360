/*
* Prefixed by https://autoprefixer.github.io
* PostCSS: v8.4.14,
* Autoprefixer: v10.4.7
* Browsers: last 120 version
*/

/* --- Commun Form Css --- */

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

::placeholder{
    color: var(--textcolor);
    font-weight: 500;
    opacity: 0.7;
}

select {
    -webkit-appearance: none;
    -moz-appearance: none;
    cursor: pointer;
}

/* Firefox */
input[type=number] {
    -moz-appearance: textfield;
}

.signinup-group {
    position: relative;
    margin: 0px 0 15px;
}

/* --- Signinup Lable Start --- */
.signinup-group .group__label {
    font-size: 14px;
    color: var(--textcolor);
    font-weight: 500;
    margin: 0;
    margin-bottom: 2px;
    margin-left: 5px;
}

.signinup-group .group__label span{
    font-size: 12px;
    color: var(--black-700);
    margin-left: 5px;
}
/* --- Signinup Lable End --- */


.signinup-group select,
.signinup-group textarea,
.signinup-group input {
    font-weight: 500;
    display: block;
    color: var(--black);
    border: 0;
    font-size: 16px;
    background-color: rgb(0 0 0 / 3%);
    border: 1px solid var(--black-300);
    width: 100%;
    outline: none;
    padding: 10px 10px;
    -webkit-border-radius: 8px;
       -moz-border-radius: 8px;
            border-radius: 8px;
    -webkit-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    -moz-transition: all 0.3s ease;
    transition: all 0.3s ease;
}

.signinup-group select[disabled],
.signinup-group textarea[disabled],
.signinup-group input[disabled] {
    opacity: 0.5;
    cursor: not-allowed;
}

/* .signinup-group select option:first-child {
    font-weight: bold;
    color: red;
    opacity: 0;
} */


.signinup-group textarea{
    min-height: 80px;
}

.signinup-group.sg-150-textarea textarea{
    min-height: 150px;
}


.signinup-group.sgli select,
.signinup-group.sgli input{
    padding-left: 54px;
}

.signinup-group.sgli .group_left_icon{
    position: absolute;
    left: 0px;
    top: 23px;
    width: 46px;
    height: 46px;
    display: flex;
    justify-content: center;
    align-items: center;
    pointer-events: none;
    font-weight: 800;
}

.signinup-group.sgli .group_left_icon::after{
    content: " ";
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    width: 1px;
    height: 50%;
    background-color: var(--black-400);
}


.signinup-group.sgri input,
.signinup-group.sgri select{
    padding-right: 54px;
}

.signinup-group.sgri .group_right_icon{
    position: absolute;
    right: 0px;
    top: 24px;
    width: 46px;
    height: 46px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.signinup-group.sgri .group_right_icon::after{
    content: " ";
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    width: 1px;
    height: 50%;
    background-color: var(--black-400);
}

.group_right_icon .gri_password{
    width: 24px;
    height: 24px;
    opacity: 0.7;
    transition: all 0.3s ease-in-out;
}

.group_right_icon.active .gri_password,
.group_right_icon:hover .gri_password{
    opacity: 1;
}

.signinup-group .down-icon{
    border-style: solid;
    border-color: var(--blue);
    border-width: 0px 2px 2px 0px;
    display: inline-block;
    padding: 4px;
    -webkit-transform: rotate(45deg);
       -moz-transform: rotate(45deg);
        -ms-transform: rotate(45deg);
         -o-transform: rotate(45deg);
            transform: rotate(45deg);
    position: absolute;
    right: 14px;
    top: 38px;
    pointer-events: none;
}

.signinup-group:not(.error-group) select:hover,
.signinup-group:not(.error-group) textarea:hover,
.signinup-group:not(.error-group) input:hover {
    border-color: var(--black);
    /* box-shadow: 0 0 1px 1px var(--black); */
}

.signinup-group:not(.error-group) textarea:focus,
.signinup-group:not(.error-group) textarea:not(:placeholder-shown),
.signinup-group:not(.error-group) select:focus,
.signinup-group:not(.error-group) select:not(:placeholder-shown),
.signinup-group:not(.error-group) input:focus,
.signinup-group:not(.error-group) input:not(:placeholder-shown) {
    border-color: var(--blue);
    background-color: var(--white);
    /* box-shadow: 0 0 1px 1px var(--blue); */
}

/* --- signinup-group-radio --- */
.signinup-group-radio {
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
       -moz-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    gap: 8px;
}

.signinup-group-radio label {
    cursor: pointer;
    position: relative;
    color: var(--black);
    font-size: 16px;
    font-weight: 500;
    margin: 4px 0;
    padding: 5px 16px;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
       -moz-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-border-radius: 50px;
       -moz-border-radius: 50px;
            border-radius: 50px;
    text-transform: capitalize;
    background-color: rgb(0 0 0 / 3%);
    border: 1px solid var(--black-300);
    -webkit-transition: 0.3s ease-in-out;
    -o-transition: 0.3s ease-in-out;
    -moz-transition: 0.3s ease-in-out;
    transition: 0.3s ease-in-out;
}


.signinup-group-radio .sgr-icon{
    position: relative;
    top: -0.5px;
    width: 22px;
    height: 22px;
    flex: 0 0 22px;
    border-radius: 50%;
    margin-right: 8px;
    background-color: transparent;
    background-position: center 2px;
    box-shadow: 0 0 0px 4px transparent inset;
    border: 2px solid var(--black-300);
    transition: 0.3s ease-in-out;
}

.signinup-group-radio input:checked + label .sgr-icon{
    border-color: var(--blue);
    background-image: url(../img/icons/check.svg);
    background-color: var(--blue);
    background-repeat: no-repeat;
    background-position: center 2px;
    background-size: 80%;
    box-shadow: none;
}

.signinup-group-radio:not(.sgr-flex-column) label:hover {
    color: var(--cp);
    border-color: var(--cp);
}

.signinup-group-radio:not(.sgr-flex-column) input:checked + label {
    border-color: var(--blue);
    background-color: var(--white);
    /* color: var(--blue); */
}


.signinup-group-radio.sgr-flex-column{
    flex-direction: column;
    gap: 0;
}

.signinup-group-radio.sgr-flex-column label{
    width: 100%;
    margin: 0;
    border: none;
    border-radius: 0px;
    background-color: transparent;
    padding: 10px 10px;
    color: var(--black-700);
    border-bottom: 1px solid var(--black-100);
}

.signinup-group-radio.sgr-flex-column label:last-child{
    border-bottom: none;
}

.signinup-group-radio.sgr-flex-column label:hover{
    color: var(--black);
}

.signinup-group-radio.sgr-flex-column label:hover .sgr-icon{
    color: var(--black);
    border-color: var(--blue);
}

.signinup-group-radio.sgr-flex-row{
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
}

.signinup-group-radio.sgr-flex-row label{
    width: calc(50% - 5px);
}

.signinup-group-radio.sgr-flex-row label:nth-last-of-type(2){
    border: none;
}


/* --- Signinup group nots --- */
.signinup-group-nots{
    background: var(--color1-500);
    -webkit-border-radius: 5px;
       -moz-border-radius: 5px;
            border-radius: 5px;
    padding: 5px 10px;
    margin-bottom: 20px;
}

.signinup-group-nots span {
    font-size: 13px;
    color: #f2f1f3;
    font-weight: 500;
}

.signinup-group-nots p {
    color: #bfbac4;
    font-size: 14px;
    margin: 2px 0 5px;
    line-height: 1.7;
}

.signinup-group-nots p > * {
    color: white;
    text-decoration: underline;
    margin: 0 3px;
}

.signinup-group-nots p > a {
    text-decoration: underline;
}

.signinup-group-nots a{
    display: inline-block;
    color: #bfbac4;
    font-weight: 500;
    -webkit-border-radius: 50px;
       -moz-border-radius: 50px;
            border-radius: 50px;
    margin-top: 5px;
    margin-bottom: 10px;
    font-size: 14px;
    -webkit-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
    text-decoration: underline;
}

.signinup-group-nots a:hover{
    color: white;
    text-decoration: underline;
}

.signinup-group-nots a svg{
    width: 18px;
    height: 18px;
    margin-right: 8px;
}


/* --- clear-input-icon --- */
.clear-input-icon{
    position: absolute;
    right: -2px;
    top: 23px;
    width: 45px;
    height: 45px;
    -webkit-transition: 0.3s ease-in-out;
    -o-transition: 0.3s ease-in-out;
    -moz-transition: 0.3s ease-in-out;
    transition: 0.3s ease-in-out;
    padding: 8px;
    cursor: pointer;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
       -moz-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
       -moz-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-border-radius: 50%;
       -moz-border-radius: 50%;
            border-radius: 50%;
}

.clear-input-icon:hover{
    color: var(--cp);
}

.clear-input-icon svg{
    width: 100%;
    height: 100%;
}


/* ---
Error Input color css
--- */
.signinup-group.error-group textarea,
.signinup-group.error-group select,
.signinup-group.error-group input{
    border-color: #c30d00;
}

.signinup-group.error-group .group__label{
    color: #c30d00 !important;
}

/* Form Error Msg */
.form-error-msg{
    display: block;
    text-align: right;
    color: #c30d00;
    font-size: 12px;
    font-weight: 600;
    margin-bottom: -5px;
    margin-top: 5px;
}

.form-error-msg .fa{
    margin-right: 5px;
}

.form-error-msg::before{
    content: "\f071";
    font: normal normal normal 14px/1 FontAwesome;
    margin-right: 5px;
}

/* --- Remember Input Checkbox --- */
.signinup-group-checkmark{
    width: fit-content;
}

.signinup-group-checkmark label{
    font-size: 14px;
    color: var(--textcolor);
    font-weight: 600;
    margin: 0;
    display: flex;
    gap: 5px;
    cursor: pointer;
}

.signinup-group-checkmark label .checkmark-icon{
    width: 20px;
    height: 20px;
    flex: 0 0 20px;
    border-radius: 5px;
    background-color: transparent;
    background-position: center;
    box-shadow: 0 0 0px 4px transparent inset;
    border: 2px solid var(--black-300);
    transition: 0.3s ease-in-out;
    cursor: pointer;
}

.signinup-group-checkmark label:hover .checkmark-icon{
    border-color: var(--blue);
}

.signinup-group-checkmark input{
    display: none;
}

.signinup-group-checkmark input:checked + label .checkmark-icon {
    background-image: url(../img/icons/check.svg);
    border-color: var(--blue);
    background-color: var(--blue);
    background-repeat: no-repeat;
    background-position: center;
    background-size: 87%  90%;
    box-shadow: none;
}


/* --- Input Autofill Css --- */
.signinup-group input:-webkit-autofill,
.signinup-group input:-webkit-autofill:hover,
.signinup-group input:-webkit-autofill:focus,
.signinup-group input:-webkit-autofill:active {
    font-weight: 400 !important;
    font-size: 16px !important;
    caret-color: black !important;
    font-family: 'Cerebri', sans-serif !important;
    /* -webkit-text-fill-color: white !important; */
    /* box-shadow: 0 0 1px 1px var(--color2), 0 0 0 100px rgb(32, 30, 35) inset !important;
    -webkit-box-shadow: 0 0 1px 1px var(--color2), 0 0 0 100px rgb(32, 30, 35) inset !important; */
}


/* --- login & submit btn --- */
.common-submit-btn,
.lsb-loader-btn,
.login-submit-btn{
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
       -moz-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
       -moz-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    padding: 12px 15px;
    -webkit-border-radius: 50px;
       -moz-border-radius: 50px;
            border-radius: 50px;
    width: 100%;
    font-weight: 800;
    color: var(--black);
    font-size: 16px;
    margin: 15px auto;
    position: relative;
    border: none;
    outline: none;
    cursor: pointer;
    border: 1px solid var(--black);
    background-color: transparent;
    transition: background-color 0.3s ease-in-out, opacity 0.3s ease-in-out, color 0.3s ease-in-out, transform 0.3s ease;
    max-width: 450px;
    width: 100%;
    letter-spacing: 0.5px;
}

.common-submit-btn[disabled],
.login-submit-btn[disabled]{
    opacity: 0.7; 
}

.common-submit-btn:hover,
.login-submit-btn:hover{
    color: white;
    background-color: var(--black);
}

.common-submit-btn:active,
.login-submit-btn:active{
    transform: scale(0.95);
}


/* --- lsb loader btn --- */
.lsb-loader-btn img{
    filter: invert(1);
    pointer-events: none;
    width: 20px;
    height: 20px;
    margin-right: 7px;
    position: relative;
    margin-right: 8px;
    -webkit-animation: rotation-btn 1s infinite linear;
       -moz-animation: rotation-btn 1s infinite linear;
         -o-animation: rotation-btn 1s infinite linear;
            animation: rotation-btn 1s infinite linear;
    -webkit-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}

@-webkit-keyframes rotation-btn {
    from {
        -webkit-transform: rotate(0deg);
                transform: rotate(0deg);
    }

    to {
        -webkit-transform: rotate(360deg);
                transform: rotate(360deg);
    }
}

@-moz-keyframes rotation-btn {
    from {
        -moz-transform: rotate(0deg);
             transform: rotate(0deg);
    }

    to {
        -moz-transform: rotate(360deg);
             transform: rotate(360deg);
    }
}

@-o-keyframes rotation-btn {
    from {
        -o-transform: rotate(0deg);
           transform: rotate(0deg);
    }

    to {
        -o-transform: rotate(360deg);
           transform: rotate(360deg);
    }
}

@keyframes rotation-btn {
    from {
        -webkit-transform: rotate(0deg);
           -moz-transform: rotate(0deg);
             -o-transform: rotate(0deg);
                transform: rotate(0deg);
    }

    to {
        -webkit-transform: rotate(360deg);
           -moz-transform: rotate(360deg);
             -o-transform: rotate(360deg);
                transform: rotate(360deg);
    }
}

@media (max-width:576px) {
    .signinup-group-nots{
        background: var(--color2-200);
    }
}




/* --- Datepicker Bx Start --- */

@media screen and (min-width:550px) {
    .signinup-group.datepicker-bx.appointment-bx{
        position: absolute;
        right: 0;
        bottom: 0;
        z-index: 11;
    }
}

@media screen and (max-width:549px) and (min-width:0px) {
    .signinup-group.datepicker-bx.appointment-bx{
        margin-top: 10px;
        width: 100%;
        margin-bottom: 10px;
    }
}


.datepicker-bx .react-datepicker__calendar-icon{
    width: 37px;
    height: 37px;
    padding: 0 !important;
    padding: 8px !important;
    box-sizing: border-box !important;
}

.datepicker-bx .react-datepicker__view-calendar-icon input{
    padding: 6px 6px 5px 35px;
}

/* --- Datepicker Bx End --- */