/* --- Mainserach Bx Start --- */
.mainsearch-bx{
    width: 100%;
    background-color: var(--white);
    background-image: url("../img/Untitled-1.webp");
    background-position: bottom right;
    background-repeat: no-repeat;
    background-size: cover;
    /* box-shadow: 0 0 40px -10px var(--black-300); */
}

.search-inner-bx{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 15px;
    flex-direction: column;
    padding: 90px 0;
    max-width: 576px;
    width: 100%;
    margin-left: auto;
}

.search-inner-bx .sib-heading{
    font-size: 32px;
    color: var(--black);
    text-align: center;
    font-weight: 600;
}

.search-inner-bx .sib-heading .sib-h-color{
    color: var(--cp);
}

.search-inner-bx .sib-sub-text{
    font-size: 16px;
    color: var(--textcolor);
    text-align: center;
    font-weight: 500;
    line-height: 1.8;
}

.sib-input-bx{
    display: flex;
    align-items: center;
    gap: 10px;
    max-width: 576px;
    width: 100%;
    padding: 5px;
    background-color: var(--white);
    border-radius: 50px;
    border: 1px solid var(--black-100);
    box-shadow: 0 0 20px -10px var(--black-300);
}

.sib-input-bx .sib-input-inner{
    display: flex;
    align-items: center;
    gap: 10px;
    width: 100%;
}

.sib-input-bx .sib-input-inner input{
    border: none;
    width: 100%;
    outline: none;
    padding-left: 15px;
    font-weight: 500;
    font-size: 18px;
}

.sib-input-bx .sib-input-inner input::placeholder{
    color: var(--textcolor);
    font-weight: 500;
    opacity: 0.7;
}

.sib-input-bx .sib-input-inner svg{
    color: var(--textcolor);
    height: 100%;
    width: 30px;
    padding: 2px;
    margin-left: 10px;
    object-fit: contain;
    object-position: center;
}

.sib-input-bx .common-btn-item{
    height: 50px;
    width: 50px;
    flex: 0 0 50px;
    padding: 0 0px;
    font-size: 16px;
}

.sib-input-bx .common-btn-item span svg{
    position: relative;
    top: 0.5px;
    width: 25px;
    height: 25px;
    object-position: center;
    object-fit: contain;
    transition: all 0.3s ease-in-out;
}
/* --- Mainserach Bx End --- */


/* --- @media Css Start Start --- */
@media screen and (max-width:768px) {
    .search-inner-bx .sib-heading{
        font-size: 28px;
    }

    .mainsearch-bx{
        background-image: none;
    }

    .search-inner-bx{
        max-width: 100%;
    }
}

@media screen and (max-width:576px) {
    .search-inner-bx .sib-heading{
        font-size: 24px;
    }

    .search-inner-bx .sib-sub-text{
        font-size: 14px;
    }

    .search-inner-bx .sib-sub-text br{
        display: none;
    }

    /* .sib-input-bx{
        padding: 0;
        flex-direction: column;
        border-radius: 0;
        background-color: transparent;
        border: none;
        box-shadow: none;
    }

    .sib-input-bx .common-btn-item{
        width: 100%;
    }

    .sib-input-bx .sib-input-inner{
        overflow: hidden;
        background-color: var(--white);
        border-radius: 50px;
        border: 1px solid var(--black-100);
        box-shadow: 0 0 20px -10px var(--black-300);;
    }

    .sib-input-bx .sib-input-inner input{
        height: 50px;
        padding-right: 20px;
        padding-bottom: 3px;
    } */
}
/* --- @media Css Start End */