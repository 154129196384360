.news-card-item{
    cursor: pointer;
    border-radius: 15px;
    overflow: hidden;
    padding-bottom: 15px;
    transition: all 0.3s ease-in-out;
    box-shadow: 0 0 0 1px var(--black-100);
}

.news-card-item:hover{
    box-shadow: 0 0 20px -5px var(--black-300);
}

.news-card-item .nci-img{
    position: relative;
    padding-top: 60%;
    overflow: hidden;
}

.news-card-item .nci-img img{
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    object-position: top center;
    object-fit: cover;
    transition: all 0.3s ease-in-out;
}

.news-card-item:hover .nci-img img{
    transform: scale(1.05);
}

.news-card-item .nci-date,
.news-card-item .nci-text,
.news-card-item .nci-heading{
    padding: 0 10px;
}

.news-card-item .nci-heading{
    font-size: 18px;
    font-weight: 600;
    color: var(--black);
    margin-top: 5px;
    display: -webkit-inline-box;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
}

.news-card-item .nci-text{
    font-size: 14px;
    font-weight: 500;
    color: var(--textcolor);
    margin-top: 5px;
    display: -webkit-inline-box;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
}

.news-card-item .nci-date{
    font-size: 13px;
    font-weight: 500;
    color: var(--black);
    margin-top: 5px;
}