/* --- Social Share Icon & Social Icons start --- */
.social-icon{
    display: flex;
    justify-content: center;
    align-items: center;
}

.social-icon .si-item{
    padding: 0px 3px;
    text-align: center;
    cursor: pointer;
}

.social-icon .si-item .si-icon{
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 38px;
    height: 38px;
    border-radius: 50%;
    overflow: hidden;
    margin: auto;
    font-size: 16px;
    color: var(--black-900);
    transition: all 0.3s ease-in-out;
}

.social-icon .si-item .si-icon::after{
    content: ""; 
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    transform: translate(-50%, -50%) scale(0);
    z-index: 0;
    opacity: 0;
    transition: all 0.3s ease-in-out;
}

.social-icon .si-item .si-icon::before{
    content: ""; 
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    background-color: var(--black-100);
    transform: translate(-50%, -50%) scale(1);
    z-index: 0;
    transition: all 0.3s ease-in-out;
}

.social-icon .si-item .si-icon .fa{
    width: 20px;
    height: 20px;
    position: relative;
    top: 0px;
    z-index: 1;
    display: flex;
    justify-content: center;
    align-items: center;
}

.social-icon .si-item:hover .si-icon{
    color: var(--white);
}

.social-icon .si-item:hover .si-icon::after{
    opacity: 1;
}

.social-icon .si-item:hover .si-icon::before{
    transform: translate(-50%, -50%) scale(0);
}

.social-icon .si-item:hover .si-icon::after{
    transform: translate(-50%, -50%) scale(1);
}

.social-icon .si-item.fb-item .si-icon::after{
    background-color: #2645a9;
    background-image: linear-gradient(45deg, #2645a9, #4e78ff);
}

.social-icon .si-item.insta-item .si-icon::after{
    background-color: #dc2743;
    background: linear-gradient(45deg, #f09433 0%,#e6683c 25%,#dc2743 50%,#cc2366 75%,#bc1888 100%);
}

.social-icon .si-item.youtube-item .si-icon::after{
    background-color: #dd0021;
}

.social-icon .si-item.whatsapp-item .si-icon::after{
    background-color: #44be53;
    background-image: linear-gradient(45deg, #09ad1d, #44be53);
}

/* .social-icon .si-item.copy-item .si-icon::after,
.social-share-icon .ssi-item.copy-item .ssi-icon{
    background-color: var(--color5);
    background-image: linear-gradient(-45deg, var(--color5), var(--color6), var(--color7), var(--color8), var(--color9));
} */
/* --- Social Share Icon & Social Icons End --- */