/* --- Breadcrumb Css --- */
.breadcrumb-outer{
    padding: 10px 0px 10px;
    z-index: 1;
    width: 100%;
    overflow: hidden;
    /* background: var(--cf); */
    border-bottom: 1px solid var(--black-100);
}

.breadcrumb-bx{   
    display: inline-flex;
    align-items: center;
    white-space: nowrap;
    width: 100%;
}

.breadcrumb-bx .breadcrumb-link{
    font-size: 14px;
    font-weight: 400;
    margin-right: 8px;
    color: var(--black);
}

.breadcrumb-bx .breadcrumb-link.breadcrumb-active{
    opacity: 0.5;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    overflow: hidden;
    pointer-events: none;
}

.breadcrumb-bx .breadcrumb-link .fa.fa-angle-right{
    margin-left: 8px;
    color: var(--black);
    position: relative;
    top: 0px;
}

.breadcrumb-bx .breadcrumb-link:hover{
    color: var(--cp);
    text-decoration: underline;
}