.checkout-main-bx{
    display: flex;
}

.checkout-main-bx .cmb-left{
    flex: 0 1 auto;
}

.checkout-main-bx .cmb-right{
    flex: 0 0 380px;
    padding: 0 20px;
}



/* Summary Bx */
.summary-bx{
    position: relative;
    border-radius: 16px;
    padding: 0 15px;
    border: 1px solid var(--black-100);
    box-shadow: 0 0 40px -20px var(--black-300);
}

.summary-bx:not(.active) .close-icon{
    display: none;
}

.summary-bx .summary-heading{
    color: var(--black);
    font-weight: 600;
    font-size: 20px;
    padding: 15px 0 10px;
}

.summary-bx .summary-data-item{
    border-bottom: 1px solid var(--black-100);
}

.summary-bx .summary-data-item:last-child{
    border-bottom: none;
}

.summary-bx .summary-data-item .sdi-inner{
    margin: 10px 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
}


.summary-bx .summary-data-item .sdi-inner > div{
    color: var(--black);
    font-weight: 600;
}

.summary-bx .summary-data-item .sdi-inner > div:nth-of-type(2){
    font-weight: 600;
    color: var(--textcolor);
}


.summary-bx .summary-data-item .sdi-inner.sdi-img{
    justify-content: flex-start;
    gap: 10px;
}

.summary-bx .summary-data-item .sdi-inner.sdi-img img{
    width: 60px;
    height: 60px;
    border-radius: 5px;
    object-fit: contain;
}

.summary-bx .summary-data-item .sdi-inner.sdi-img div{
    font-size: 16px;
    font-weight: 600;
}

.summary-bx .summary-data-item .sdi-inner.sdi-img div span{
    display: block;
    font-size: 16px;
    font-weight: 600;
    margin-top: 1px;
    color: var(--textcolor);
}


.summary-bx .summary-data-item .sdi-inner.sdi-total > div{
    font-size: 20px;
    font-weight: 600;
    color: var(--black);
}


/* --- Summary Bottom Bx --- */
.summary-bottom-bx{
    display: block;
    padding: 0 2px;
}

.summary-bottom-bx > div:nth-child(1){
    color: var(--black);
    font-weight: 600;
    display: none;
    cursor: pointer;
}

.summary-bottom-bx > div:nth-child(1) span{
    display: block;
    font-size: 16px;
    font-weight: 600;
    color: var(--textcolor);
}

.summary-bottom-bx > div:nth-child(1) span svg{
    width: 20px;
    height: 20px;
    margin-left: 5px;
    position: relative;
    top: -2px;
    color: var(--cp);
}

/* --- @media Summary Bottom Bx --- */
@media screen and (max-width:992px) {

    .summary-bx{
        position: fixed;
        left: 0;
        bottom: 0;
        width: 100%;
        z-index: 111;
        background: white;
        transform: translateY(100%);
        border-radius: 16px 16px 0 0;
        gap: 15px;
        transition: all 0.3s ease-in-out;
    }

    .summary-bx.active{
        transform: translateY(0);
    }

    .summary-bottom-bx{
        display: flex;
        justify-content: space-between;
        align-items: center;
        position: fixed;
        left: 0;
        bottom: 0px;
        width: 100%;
        background-color: var(--white);
        padding: 10px 15px;
        z-index: 11;
        box-shadow: 0 0 40px -20px var(--black-300);
        border-top: 1px solid var(--black-100);
    }
        
    .summary-bottom-bx > div:nth-child(1){
        display: block;
    }

    .summary-bottom-bx .lsb-loader-btn,
    .summary-bottom-bx .common-submit-btn{
        font-size: 14px;
        margin: 6px auto;
        padding: 8px 14px;
    }

    .checkout-overlay.checkout-active{
        visibility: visible;
        opacity: 1;
    }

    .checkout-bottom-padding{
        padding-bottom: 80px;
    }
}






/* Meadi Css */
@media screen and (max-width:992px) {
    .checkout-main-bx{
        flex-direction: column;
    }

    .checkout-main-bx .cmb-right{
        padding: 0;
        flex: initial;
    }
}